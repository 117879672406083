import React from 'react';
import {useFontSizeStore} from "../stores/useFontSizeStore";
import Image1 from '../assets/Reference_automatika.jpg'

const Automation10 = () => {

  const options = useFontSizeStore(state => state.defs)
  const textSizeSelected = useFontSizeStore(state => state.textSizeSelected)

  return (
    <>


      <div className="container">
        <div className="row">
          <div className="col-12">
            <p style={options[textSizeSelected].styleHead}>REFERENCE AUTOMATIKA</p>
          </div>
        </div>

        <div className="row">
          <div className="col-10">
            <img src={Image1}  style={{ width: '100%', height: '300px', padding:'0px'}} alt="Oprema1" />
          </div>
        </div>

        <div className="row mt-1">
          <div className="col-12">
            <p style={options[textSizeSelected].styleNormal}>
              Automatika je u cijelosti projektirana i izrađena u PINKY-S d.o.o. Za brže rješavanje mogućih problema svaka automatizirana betonara povezana je internetom ili VPN mrežom. Svaka automatizirana betonara ima i 24 satnu programsku podršku, a ako treba možemo pomoći i savjetom oko mehaničkih problema vezanih za nju.
              <br/>
              <br/>
              Pri rješavanju Vaših tehnoloških problema PINKY-S koristi vlastite inovacije i program čime pomaže poboljšanju kvalitete i/ili brzini izrade Vaših gotovih proizvoda. U suradnji sa svojim poslovnim partnerima PINKY-S obavlja i servis betonara te strojeva i uređaja za rad s pigmentima i kemijskim dodacima.
            </p>
          </div>
        </div>

        <div className="row mt-1">
          <div className="col-12">
            <p style={options[textSizeSelected].styleNormal}>
              1. NIKOLA d.o.o. VIROVITICA<br/>
              2. GRAČEC d.o.o. BETONARA GRAČEC<br/>
              3. KAMENOLOM GORJAK d.o.o. GALANTERIJA 1<br/>
              4. KAMING d.d LJUBEŠČICA<br/>
              5. KONSTRUKTOR ZLATAR d.o.o. GREDELJ<br/>
              6. NEXE GRUPA: BETONARA BLATO<br/>
              7. BMB d.o.o. ZELINA<br/>
              8. KAMENOLOM GORJAK d.o.o. GALANTERIJA 2<br/>
              9. NEXE GRUPA: BETONARA SISAK<br/>
              10. NEXE GRUPA: BETONARA OSIJEK<br/>
              11. NEXE GRUPA: BETONARA VINKOVCI<br/>
              12. NEXE GRUPA: BETON MIX SESVETE<br/>
              13. NEXE GRUPA: ASFALT I BETON PUT SARAJEVO<br/>
              14. NEXE GRUPA: BETONARA NOVI SAD<br/>
              15. KONSTRUKTOR ZLATAR d.o.o. ZABOK<br/>
              16. FRANTO d.o.o. BETONARA ZADAR<br/>
              17. NEXE GRUPA: BETONARA TRSTENIK<br/>
              18. NEXE GRUPA: IGMA d.o.o. BETONARA DRNJE KOPRIVNICA<br/>
              19. BETON LUČKO d.o.o. POGON JASTREBARSKO<br/>
              20. TEMPO-MEŽNAR KARLOVAC<br/>
              21. LAGER BAŠIĆ d.o.o. ZAGREB<br/>
              22. BEST IZGRADNJA d.o.o. NOVI SAD<br/>
              23. NEXE GRUPA: BETONARA STUPNIK<br/>
              24. TRANSPORT BETON LUBINA d.o.o. POSEDARJE<br/>
              25. OPECO d.d. VIROVITICA<br/>
              26. NEXE GRUPA: BETONARA ZEMUN - BATAJNICA<br/>
              27. KUKEC d.o.o. - BETONARA IVANIĆ GRAD<br/>
              28. QUADRO d.o.o. KRIŽOVEC: MALA BETONARA 0.5 m3<br/>
              29. QUADRO d.o.o. KRIŽOVEC: VELIKA BETONARA 1 m3<br/>
              30. ZAGORJE TEHNOBETON d.d. VARAŽDIN: POGON B3<br/>
              31. ZE-PROM d.o.o. SV. IVAN ZELINA<br/>
              32. ZAGORJE TEHNOBETON d.d. VARAŽDIN: POGON B5 - MAĐARSKA GYOR<br/>
              33. NEXE GRUPA: BETONARA BEOGRAD - OSTRUŽNICA<br/>
              34. KUKEC d.o.o. - BETONARA BJELOVAR<br/>
              35. NEXE GRUPA: ASFALT I BETON PUT TUZLA<br/>
              36. HIDROREGULACIJA d.d. - BETONARA BJELOVAR<br/>
              37. RR COMPANY - BETONARA ZENICA, BIH<br/>
              38. ZAGORJE TEHNOBETON d.d. VARAŽDIN: POGON B2<br/>
              39. QUADRO d.o.o. KRIŽOVEC: AUTOMATIZACIJA SKLADIŠTA<br/>
              40. NEXE GRUPA: BETONARA ĐAKOVO<br/>
              41. BETAPLAST: AUTOMATIZACIJA SKLADIŠTA<br/>
              42. NEXE GRUPA: IGMA d.o.o. BETONARA 2 DRNJE KOPRIVNICA<br/>
              43. ZAGORJE TEHNOBETON d.d. VARAŽDIN: POGON B1<br/>
              44. BETAPLAST: AUTOMATIZACIJA SKLADIŠTA 2<br/>
              45. HREN: DONJA STUBICA - MALA BETONARA 0.5 m3<br/>
              46. HREN: DONJA STUBICA - VELIKA BETONARA 1 m3<br/>
              47. TEHNIKA: ZAGREB - BETONARA ALŽIR<br/>
              48. BETAPLAST: LINIJA ZA IZRADU OPLOČNIKA<br/>
              49. HERING d.d: ŠIROKI BRIJEG<br/>
              50. NEXE GRUPA: UGLJEVIK, BOSNA I HERCEGOVINA<br/>
              51. TRANSPORT BETON LUBINA d.o.o. KNIN<br/>
              52. BETON LUČKO d.o.o. POGON JASTREBARSKO (mala betonara)<br/>
              53. PRESOFLEX GRADNJA d.o.o. POŽEGA<br/>
              54. TRANSPORT BETON LUBINA d.o.o. ŠIBENIK<br/>
              55. KATRAPILAR d.o.o. BIHAĆ<br/>
              56. ZIDAR ŽIVANOVIĆ d.o.o. UGLJEVIK<br/>
              57. NOVOTEHNA d.d. RIJEKA<br/>
              58. MUCIĆ & Co. LJUBUŠKI<br/>
              59. TEMPO-VRANICA d.d. ODŽAK<br/>
              60. JARC CEMENTNI IZDELKI - MEDVODE<br/>
              61. QUADRO d.o.o. KRIŽOVEC: STROJ ZA IZRADU OPLOČNIKA<br/>
              62. QUADRO d.o.o. KRIŽOVEC: STROJ ZA OBRADU OPLOČNIKA ČEKIĆIMA<br/>
              63. NEXE GRUPA: ROGATICA, BOSNA I HERCEGOVINA<br/>
              64. PUT INŽENJERING: BETONARA 1 - NIŠ, REPUBLIKA SRBIJA<br/>
              65. PUT INŽENJERING: BETONARA 2 - NIŠ, REPUBLIKA SRBIJA<br/>
              66. ZAGREBAČKO KAZALIŠTE LUTAKA: ZAGREB<br/>
              67. UNIJA BETON d.o.o.: ZAGREB<br/>
              68. KAMENOLOM GORJAK d.o.o.: BETONARA ZABOK<br/>
              69. D & M BAŠIĆ d.o.o.: IMOTSKI<br/>
              70. ABK d.o.o.: ZAPUŽANE, BENKOVAC<br/>
              71. BETON LUČKO d.o.o.: POGON KARLOVAC<br/>
              72. MEĐIMURJE PMP d.o.o.: ČAKOVEC<br/>
              73. EKOGRADNJA d.o.o.: ZRENJANIN - BETONARA VELIKA<br/>
              74. EKOGRADNJA d.o.o.: ZRENJANIN - BETONARA MALA<br/>
              75. STREŠNIKI GOLOB d.o.o.: MIRNA PEČ, NOVO MESTO<br/>
              76. BETAPLAST: BETONARA MALA (FINI SLOJ)<br/>
              77. PRAJO BETON d.o.o. : VIROVITICA<br/>
              78. BAGERKOP ROBERTO d.o.o. : HRŽENICA<br/>
              79. ATPR KNEŽEVIĆ : ČAČAK<br/>
              80. TRANSPORT BETON LUBINA d.o.o. KNIN (4)<br/>
              81. CD HIS d.o.o.: NIŠ, ORAOVAČKO POLJE<br/>
              82. ABK d.o.o.: ZAPUŽANE, BENKOVAC<br/>
              83. GPI d.o.o.: KONJIC, BOSNA I HERCEGOVINA<br/>
              84. BOLKOP d.o.o.: PTUJ, SLOVENIJA<br/>
              85. QUADRO d.o.o. KRIŽOVEC: STROJ ZA CJEPANJE BETONSKI ELEMENATA<br/>
              86. TERMOBETON d.o.o.: BREZA, BOSNA I HERCEGOVINA<br/>
              87. MIP MOSTAR d.o.o.: MOSTAR, BOSNA I HERCEGOVINA<br/>
              88. PLOVPUT d.o.o.: SPLIT<br/>
              89. KOSTIĆ :ČAČAK, MALA BETONARA<br/>
              90. KOSTIĆ :ČAČAK, VELIKA BETONARA<br/>
              91. ABK d.o.o.: ZAPUŽANE, MALA I VELIKA BETONARA<br/>
              92. EKOGRADNJA d.o.o.: ZRENJANIN - STOJ ZA IZRADU OPLOČNIKA I POGON PAKIRANJA<br/>
              93. BETAPLAST: BETONARA VELIKA (GRUBI SLOJ)<br/>
              94. ALMY d.o.o. ZENICA: TORANJSKA BETONARA<br/>
              95. BOSSIN d.o.o. MOSTAR: REGALNO SKLADIŠTE<br/>
              96. HP INVESTING MOSTAR: STROJ ZA IZRADU OPLOČNIKA<br/>
              97. NEXE GRUPA: NAŠICE TRANSPORT BETON<br/>
              98. KUKEC d.o.o.: PAKRAC TRANSPORT BETON<br/>
              99. PRAJO BETON d.o.o. : TORANJSKA BETONARA VIROVITICA<br/>
              100. INZINJERING 1 d.o.o.: JELAH BETONARA TRANSPORT + PROIZVODNJA<br/>
              101. HIDROELEKTRA NISKOGRADNJA d.d. ZAGREB<br/>
              102. EKOGRADNJA d.o.o. ZRENANJIN 2<br/>
              103. UNIJA BETON d.o.o. ZAGREB<br/>
              104. TRANSPORT BETON LUBINA d.o.o. - 4, KNIN<br/>
              105. KAMENOLOM BOJNAK, CRES<br/>
              106. BETAPLAST d.o.o. TOTOVEC - TRANSPORT BETON<br/>
              107. BETAPLAST d.o.o. -TOTOVEC - BRIKETI<br/>
              108. TRANSPORT BETON LUBINA d.o.o.- BRAČ<br/>
              109. ADRIA BETON d.o.o. KASTAV<br/>
              110. RESNIK BETON d.o.o.- ZAGREB - TRANSPORT BETON<br/>
              111. RESNIK BETON d.o.o.- ZAGREB - AUTOMATSKI SKREPER<br/>
              112. MUCIĆ & CO d.o.o.- LJUBUŠKI<br/>
              113. BOSSIN d.o.o.- MOSTAR<br/>
              114. BETAPLAST d.o.o. TOTOVEC - PAKIRNICA<br/>
              115. ALMY d.o.o. ZENICA<br/>
              116. STANIŠLJEVIĆ PROMET d.o.o. - BANJA LUKA<br/>
              117. TRANSPORT BETON LUBINA d.o.o.- KNIN<br/>
              118. MOBECO d.o.o. - KRŠKO<br/>
              119. INŽINJERING 1 d.o.o. - JELAH<br/>
              120. TVORNICA CEMENTA LUKAVAC d.d. - LUKAVAC<br/>
              121. BIG BOYS GRADNJA -BETONARA - NOVSKA<br/>
              122. BETAPLAST d.o.o. - PAKIRNICA GOTOVIH PROIZVODA -TOTOVEC<br/>
              123. TRANSPORT BETON LUBINA d.o.o. - MOBILNA BETONARA<br/>
              124. VODOPRIVRENA VINKOVCI d.d.<br/>
              125. MI BLOK - STROJ ZA IZRADU OPLOČNIKA<br/>
              126. MI BLOK - REGALNO SKLADIŠTE<br/>
              127. UNIJA BETON d.o.o. - ŽITNJAK 2<br/>
              128. PUT INŽENJERING - POGON PAZOVA DUO BETONARA<br/>
              129. BOSSIN d.o.o. - GRANUMIN - MOSTAR<br/>
              130. TVORNICA CEMENTA LUKAVAC d.d. - BETONARA - BANJA LUKA<br/>
              131. SUNCE BETON d.o.o. - BETONARA - BEOGRAD<br/>
              132. PMP MEĐIMURJE d.o.o. - BETONARA - IVANOVEC<br/>
              133. UNIJA BETON d.o.o.- BETONARA - KOPRIVNICA<br/>
              134. UNIJA BETON d.o.o. - BETONARA - ŽITNJAK 3<br/>
              135. SIP KOMERC d.o.o. - HENKE STROJ ZA IZRADU OPLOČNIKA I REGALNO SKLADIŠTE - BANJA ARANĐELOVAC<br/>
              136. OBLAK BETON d.o.o. - REGALNO SKLADIŠTE<br/>
              137. OBLAK BETON d.o.o. - GRANUMIN MINI<br/>
              138. PUT INŽENJERING - MOBILNA BETONARA SBM<br/>
              139. VALIS GRUPA j.d.o.o. - AUTOMATIKA BETONARE<br/>
              140. NEXE d.d. - REKONSTRUKCIJA AUTOMATIKE BETONARE - STUPNIK<br/>
              141. DM BAŠIĆ d.o.o. - REKONSTRUKCIJA ELEKTRO OPREME BETONARE - IMOTSKI<br/>
              142. TRANSPORT BETON LUBINA d.o.o. - REKONSTRUKCIJA ELEKTRO OPREME BETONARE - ŠIBENIK<br/>
              143.BETAPLAST d.o.o. - AUTOMATIKA STROJA ZA IZRADU OPLOČNIKA OMAG 1<br/>
              144. MOBECO d.o.o. - AUTOMATIKA KRANSKOG DISTRIBUTERA BETONA - KRŠKO<br/>
            </p>
          </div>
        </div>



      </div>
    </>
  );
};

export default Automation10;