import React from 'react';
import {useFontSizeStore} from "../stores/useFontSizeStore";
import Image1 from "../assets/analiza_1.jpeg";
import Image2 from "../assets/analiza_2.jpeg";
import Image3 from "../assets/analiza_3.jpeg";
import Image4 from "../assets/analiza_4.jpeg";
import Image5 from "../assets/analiza_5.jpeg";
import Image6 from "../assets/analiza_6.jpeg";

const Automation11 = () => {

  const options = useFontSizeStore(state => state.defs)
  const textSizeSelected = useFontSizeStore(state => state.textSizeSelected)

  return (
    <>

      <div className="row">
        <div className="col-4 pr-1">
          <img src={Image1}  style={{ width: '100%', height: '200px', padding:'0px'}} alt="Analiza1" />
        </div>
        <div className="col-4 pr-1">
          <img src={Image2}  style={{ width: '100%', height:'200px', padding:'0px'}} alt="Analiza2" />
        </div>
        <div className="col-4">
          <img src={Image3}  style={{ width: '100%', height: '200px', padding:'0px'}} alt="Analiza3" />
        </div>
      </div>

      <div className="row mt-3">
        <div className="col-4 pr-1">
          <img src={Image4}  style={{ width: '100%', height: '200px', padding:'0px'}} alt="Analiza4" />
        </div>
        <div className="col-4 pr-1">
          <img src={Image5}  style={{ width: '100%', height:'200px', padding:'0px'}} alt="Analiza5" />
        </div>
        <div className="col-4">
          <img src={Image6}  style={{ width: '100%', height: '200px', padding:'0px'}} alt="Analiza6" />
        </div>
      </div>

      <div className="container">
        <div className="row">
          <div className="col-12">
            <p style={options[textSizeSelected].styleHead}>PROGRAMSKE ANALIZE</p>
          </div>
        </div>

        <div className="row mt-1">
          <div className="col-12">
            <p style={options[textSizeSelected].styleNormal}>
              Uspješnost automatizacije se vidi kroz analize. Imamo mogućnost prikaza svih vrsta dokumenata: potrošnja materijala, skladišna analiza, analiza proizvodnje po danima, otpremnica, izdatnica, evidencija prijevoza i mnoge druge te ih možemo prikazati grafički.
              <br/>
              <br/>
              Automatika je u cijelosti projektirana i izrađena u PINKY-S d.o.o. Za brže rješavanje mogućih problema svaka automatizirana betonara povezana je internetom ili VPN mrežom. Svaka automatizirana betonara ima i 24 satnu programsku podršku, a ako treba možemo pomoći i savjetom oko mehaničkih problema vezanih za nju.
            </p>
          </div>
        </div>

      </div>
    </>
  );
};

export default Automation11;