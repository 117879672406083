import React from 'react';
import {useFontSizeStore} from "../stores/useFontSizeStore";
import NewsDetail from "./NewsDetail";

const News = () => {

  const options = useFontSizeStore(state => state.defs)
  const textSizeSelected = useFontSizeStore(state => state.textSizeSelected)

  const head1 = {}
  head1.headline = 'Automatika'
  head1.text = 'Za našeg dugogodišnjeg partnera u Hrvatskoj započeli smo novi projekt: Kompletna rekonstrukcija linije MASA.'
  head1.image = null
  head1.date = '25.03.21. '

  const head2 = {}
  head2.headline = 'Automatika'
  head2.text = ' Za partnera u Hrvatskoj uspješno smo završili projekt: Kompletna rekonstrukcija linije OMAG 1. '
  head2.image = null
  head2.date = '19.03.21.'

  const head3 = {}
  head3.headline = 'Automatika'
  head3.text = 'S partnerom iz Slovenije potpisan ugovor za novi projekt: Rekonstrukcija upravljanja linijom Zenit.'
  head3.image = null
  head3.date = '19.03.21.'

  const head4 = {}
  head4.headline = 'Zapošljavamo'
  head4.text = 'Zapošljavamo - raspisan je natječaj za radno mjesto:  Inženjer istraživanja i razvoja kemijskih proizvoda.'
  head4.image = null
  head4.date = '03.03.21.'

  const head5 = {}
  head5.headline = 'Oprema'
  head5.text = 'Za kupca u Hrvatskoj uspješno smo završili montažu betonare ELKOMIX 120 QUICK MASTER, proizvođača ELKON.'
  head5.image = null
  head5.date = '20.01.21.'

  const head6 = {}
  head6.headline = 'Automatika, oprema'
  head6.text = 'Za našeg dugogodišnjeg partnera u BIH, naš tim iz odjela automatizacije završio je montažu opreme vlastite proizvodnje: UREĐAJ ZA INLINE IMPREGNACIJU.'
  head6.image = null
  head6.date = '15.01.21.'

  const head7 = {}
  head7.headline = 'Oprema'
  head7.text = 'U proizvodnji našeg dugogodišnjeg slovenskog kupca montirali smo opremu vlastite proizvodnje - GRANUMIN MINI, za doziranje pigmenta u granulama.'
  head7.image = null
  head7.date = '09.12.20. '

  return (
    <div className="container">
      <div className="row mt-4">
        <div className="col-8">
          <p style={options[textSizeSelected].styleOver}>Novosti</p>
        </div>
        <div className="col-4">

        </div>
      </div>

      <NewsDetail head={head1}/>
      <NewsDetail head={head2}/>
      <NewsDetail head={head3}/>
      <NewsDetail head={head4}/>
      <NewsDetail head={head5}/>
      <NewsDetail head={head6}/>
      <NewsDetail head={head7}/>
    </div>
  );
}

export default News;
