import React from 'react';
import {useFontSizeStore} from "../stores/useFontSizeStore";

const Pigments2 = () => {

  const options = useFontSizeStore(state => state.defs)
  const textSizeSelected = useFontSizeStore(state => state.textSizeSelected)

  return (
    <>

      <div className="container">


        <div className="row mt-1">
          <div className="col-lg-10 col-sm-12">
            <p style={options[textSizeSelected].styleNormal}>
              PINKY-S d.o.o je tvrtka fokusirana na koncept „boja je ključ“, što znači da je naš cilj hvatanje u koštac s novim izazovima u sveprisutnijoj bojanoj građevinskoj, odnosno betonskoj industriji. Budući da naša tvrtka nudi veliki asortiman proizvoda, možemo zadovoljiti sve potrebe za pigmentima u građevinarstvu, u industriji boja i premaza, industriji papira, a isto tako potrebu kod tiskarskih boja, tekstila, itd. Našim kupcima ne nudimo samo gotove proizvode, već i potrebno znanje i stručnu pomoć pri samoj aplikaciji. Efektivno korištenje pigmenata može uvelike utjecati na profit i sam uspjeh Vaše tvrtke.
            </p>
          </div>
          <div className="col-lg-2 col-sm-12">

          </div>
        </div>

        <div className="row mt-1">
          <div className="col-lg-10 col-sm-12">
            <p style={options[textSizeSelected].styleNormal}>
              Naši pigmenti imaju vrlo širok raspon primjene i mogu se koristiti za proizvodnju praktički neograničene palete bogatih, izdržljivih boja, te s lakoćom  ostvaruju željeno nijansiranje. PINKY-S ima dugogodišnje iskustvo stečeno iz brojnih područja bojane industrije i upravo to iskustvo nama pomaže da Vama unaprijedimo i poboljšamo proizvodni asortiman. U svakom području, PINKY-S ima tim stručnjaka, uvijek spremnih za savjet i pomoć.
            </p>
          </div>
          <div className="col-lg-2 col-sm-12">

          </div>
        </div>

        <div className="row mt-1">
          <div className="col-lg-10 col-sm-12">
            <p style={options[textSizeSelected].styleNormal}>
              Što god proizvodite, naš tim je spreman surađivati s Vama kako biste stvorili boju i efekt koji najbolje odgovara vašim zahtjevima. Nešto takvo, može se postići samo kroz detaljnu raspravu, te identifikaciju samih sirovina koje utječu na boje. Osim toga, predstavljanje naših pigmenata uvijek je dostupno u našem laboratoriju, naravno uz  usporedbu s konkurencijom. Kombiniranjem Vaših i naših znanja, stručnosti i sposobnosti, uz malo mašte, sada možete postići rezultate koji će stvarno zadovoljiti Vaša očekivanja.
            </p>
          </div>
          <div className="col-lg-2 col-sm-12">

          </div>
        </div>

        <div className="row mt-1">
          <div className="col-lg-10 col-sm-12">
            <p style={options[textSizeSelected].styleNormal}>
              Kako se pri radu s pigmentima pojavljuju brojni problemi: određivanje potrebne količine pigmenta, apliciranje premaza, miješanje pigmenta u beton, priprema tekućeg pigmenta, trošenje dijelova strojeva i sl., PINKY-S je počeo proizvoditi specifičnu opremu za rad s pigmentima:
            </p>
          </div>
          <div className="col-lg-2 col-sm-12">

          </div>
        </div>

        <div className="row mt-1">
          <div className="col-9 col-sm-12">
            <ul className="list-group list-group-flush">
              <li className="list-group-item">
                <p style={options[textSizeSelected].styleNormal}>
                  Strojeve za pripremu i doziranje tekućih pigmenata
                </p>
              </li>
              <li className="list-group-item">
                <p style={options[textSizeSelected].styleNormal}>
                  Aparate za nanošenje premaza
                </p>
              </li>
              <li className="list-group-item">
                <p style={options[textSizeSelected].styleNormal}>
                  Rezervne dijelove opreme za proizvodnju betonskog crijepa i drugih betonskih proizvoda
                </p>
              </li>
            </ul>
          </div>
        </div>

      </div>
    </>
  );
};

export default Pigments2;
