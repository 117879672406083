import ImageEu from '../assets/EU-projekt.jpeg'
import ImageEU2 from '../assets/montirana3.jpg'
import ImageEU3 from '../assets/montirano.jpeg'

import {useFontSizeStore} from "../stores/useFontSizeStore";

const EUProjects = () => {

  const openInNewTab = (url) => {
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
    if (newWindow) newWindow.opener = null
  }

  const options = useFontSizeStore(state => state.defs)
  const textSizeSelected = useFontSizeStore(state => state.textSizeSelected)

  return (

    <div className="container">
      <div className="row">
        <div className="col-8">
          <p style={options[textSizeSelected].styleOver}>EU projekti</p>
        </div>
        <div className="col-4">

        </div>
      </div>

      <div className="row mt-3">
        <div className="col-12">
          <p style={options[textSizeSelected].styleHead}>Informatizacija poslovanja poduzeća PINKY-S d.o.o. </p>
        </div>
      </div>

      <div className="row mt-3">
        <div className="col-lg-8 col-sm-12">
          <p style={options[textSizeSelected].styleNormal}>
            Poduzeće Pinky-s d.o.o. renominirano je poduzeće s dugogodišnjom tradicijom u industriji betona. Razvojem poduzeća vlastita proizvodnja aditiva te automatizacija sustava betonara postala je primarna djelatnost. Dugogodišnje iskustvo vezano uz problematiku i tehnologije pri proizvodnji betona omogućilo je da izvedemo tehničko-tehnološka rješenja za bilo koji problem vezan uz betonsku industriju, koristeći se pri tome vlastitim inovacijama, programima i opremom.
          </p>
        </div>
      </div>

      <div className="row mt-3">
        <div className="col-lg-8 col-sm-12">
          <p style={options[textSizeSelected].styleNormal}>
            U rujnu 2019. godine, započeli smo s provedbom projekta „Informatizacija poslovnih procesa poduzeća Pinky-s d.o.o.“, koji je sufinanciran iz natječaja „Poboljšanje konkurentnosti i učinkovitosti MSP-a kroz informacijske i komunikacijske tehnologije (IKT) - 2“. Provedba projekta traje do ožujka 2021. godine. Ukupna vrijednost projekta iznosi 417.684,86 HRK, od toga dobivena bespovratna sredstva iznose 243.555,04 HRK.
          </p>
        </div>
      </div>

      <div className="row mt-3">
        <div className="col-lg-8 col-sm-12">
          <p style={options[textSizeSelected].styleNormal}>
            Ulaganjem u informatizaciju poslovanja kroz uvođenje ERP sustava i proces automatizacije betonara  povezani su poslovni procesi poduzeća te se na taj način utjecalo na smanjenje troškova, povećanu produktivnost, olakšani su rutinski poslovi, informacije su lako dostupne te se omogućilo brže donošenje odluka.
          </p>
        </div>
      </div>

      <div className="row mt-3">
        <div className="col-lg-8 col-sm-12">
          <p style={options[textSizeSelected].styleNormal}>
            Aktivnosti projekta uključuju nabavu ERP sustava te informatizaciju procesa automatizacije betonara potrebne za modernizaciju poslovanja. Projektom će se utjecati na povećanje prihoda od prodaje i te otvaranje novih radnih mjesta do 2023. godine.
          </p>
        </div>
      </div>

      <div className="row mt-3">
        <div className="col-lg-8 col-sm-12">
          <p style={options[textSizeSelected].styleNormal}>
            Za sve dodatne informacije slobodno se obratite na kontakt:
          </p>
        </div>
      </div>
      <div className="row mt-3">
        <div className="col-lg-8 col-sm-12">
          <p style={options[textSizeSelected].styleNormal}>Spomenka Koprivec, pinky-s@pinky-s.com, 040/ 360 700</p>
        </div>
      </div>

      <div className="row mt-3">
        <div className="col-lg-8 col-sm-12">
          <p style={options[textSizeSelected].styleNormal}>Projekt je sufinancirala Europska unija iz Europskog fonda za regionalni razvoj.</p>
        </div>
      </div>

      <div className="row mt-3">
        <div className="col-lg-8 col-sm-12">
          <p style={options[textSizeSelected].styleNormal} onClick={() => openInNewTab('https://www.strukturnifondovi.hr')}> www.strukturnifondovi.hr</p>
        </div>
      </div>

      <div className="row mt-3">
        <div className="col-lg-8 col-sm-12">
          <p style={options[textSizeSelected].styleNormal} onClick={() => openInNewTab('https://strukturnifondovi.hr/eu-fondovi/esi-fondovi-2014-2020/op-konkurentnost-i-kohezija/')}>www.strukturnifondovi.hr/op-konkurentnost-i-kohezija-2014-2020-779</p>
        </div>
      </div>

      <div className="row mt-3">
        <div className="col-lg-8 col-sm-12">
          <img src={ImageEu} className="img-fluid"  alt="EUfonds" />
        </div>
      </div>
      <div className="row">
        <div className="col-lg-8 col-sm-12">
          <p style={options[textSizeSelected].styleSub}>09/2019.</p>
        </div>
      </div>


      <div className="row mt-3">
        <div className="col-12">
          <p style={options[textSizeSelected].styleHead}>Povećanje proizvodnih kapaciteta provedbom projekta iz natječaja „E-impuls“</p>
        </div>
      </div>

      <div className="row mt-3">
        <div className="col-lg-8 col-sm-12">
          <p style={options[textSizeSelected].styleNormal}>Sa zadovoljstvom Vas obavještavamo da provodimo projekt „Povećanje konkurentnosti poduzeća Pinky-S d.o.o. uvođenjem modernizirane opreme u proizvodni proces“ sufinanciran u okviru Javnog poziva E-impuls. Ukupna vrijednost projekta iznosi 340.281,63 HRK, dok je dobiveni iznos bespovratnih sredstva 228.669,25 HRK. Provedba projekta započela je 1. listopada 2017. godine te traje do 1. listopada 2018. godine.</p>
        </div>
      </div>

      <div className="row">
        <div className="col-lg-8 col-sm-12">
          <p style={options[textSizeSelected].styleNormal}>Kroz 12. mjeseci trajanja projekta, povećat ćemo naše proizvodne kapacitete u proizvodnji aditiva, odnosno dodataka za betonsku galanteriju za koju koristimo posebnu recepturu razvijenu i testiranu u vlastitom laboratoriju. Cilj projekta je uvođenjem nove opreme u proizvodni proces ostvariti pokazatelje projekta – očuvanje radnih mjesta, otvaranje novog radnog mjesta te povećanje prihoda od prodaje za 21,55 %.</p>
        </div>
      </div>

      <div className="row">
        <div className="col-lg-8 col-sm-12">
          <p style={options[textSizeSelected].styleNormal}>U pripremi i provedbi projektnih aktivnosti sudjeluju vanjski stručnjaci iz REDEA-e koji posjeduju dugogodišnje iskustvo u pripremi i provođenju projekata, provođenju postupka javne nabave, kao i savjetovanju poduzetnika.</p>
        </div>
      </div>

      <div className="row mt-3">
        <div className="col-lg-8 col-sm-12">
          <p style={options[textSizeSelected].styleNormal}>Za sve dodatne informacije slobodno nam se obratite:</p>
        </div>
      </div>

      <div className="row mt-3">
        <div className="col-lg-8 col-sm-12">
          <p style={options[textSizeSelected].styleNormal}>Spomenka Koprivec, pinky-s@pinky-s.com, 040/ 360 700</p>
          <p style={options[textSizeSelected].styleNormal}>Kristina Pevec, kristina.pevec@redea.hr, 040/ 395 572</p>
        </div>
      </div>

      <div className="row mt-3">
        <div className="col-lg-8 col-sm-12">
          <p style={options[textSizeSelected].styleNormal}>Projekt je sufinancirala Europska unija iz Europskog fonda za regionalni razvoj.</p>
        </div>
      </div>

      <div className="row mt-3">
        <div className="col-lg-8 col-sm-12">
          <p style={options[textSizeSelected].styleNormal} onClick={() => openInNewTab('https://www.strukturnifondovi.hr')}> www.strukturnifondovi.hr</p>
        </div>
      </div>

      <div className="row mt-3">
        <div className="col-lg-8 col-sm-12">
          <p style={options[textSizeSelected].styleNormal} onClick={() => openInNewTab('https://strukturnifondovi.hr/eu-fondovi/esi-fondovi-2014-2020/op-konkurentnost-i-kohezija/')}>www.strukturnifondovi.hr/op-konkurentnost-i-kohezija-2014-2020-779</p>
        </div>
      </div>

      <div className="row mt-3">
        <div className="col-lg-8 col-sm-12">
          <img src={ImageEu} className="img-fluid"  alt="EUfonds" />
        </div>
      </div>
      <div className="row">
        <div className="col-lg-8 col-sm-12">
          <p style={options[textSizeSelected].styleSub}>01/2019.</p>
        </div>
      </div>





      <div className="row mt-3">
        <div className="col-12">
          <p style={options[textSizeSelected].styleHead}>Certifikacijom proizvoda do tržišta</p>
        </div>
      </div>

      <div className="row mt-3">
        <div className="col-lg-8 col-sm-12">
          <p style={options[textSizeSelected].styleNormal}>Poduzeće PINKY-S d.o.o. za zastupanje inozemnih tvrtki,uvoz-izvoz i ugostiteljstvo je poduzeće s dugogodišnjom tradicijom, osnovano 1993. g. Posluje u industriji betona te industriji boja i lakova, za koje nudi široku paletu proizvoda i usluga vlastite inovativne proizvodnje. Ideja proizvodnje aditiva javila se još prije desetak godina kada smo opremili vlastiti laboratorij za razvoj i krenuli s proizvodnjom aditva za betonsku galenteriju. U želji da upotpuni ponudu vlastitih proizvoda za betonsku industriju, stručni tim naših kemiskih inžinjera 2017. godine završio je i razvoj aditiva za transportne betone.</p>
        </div>
      </div>

      <div className="row mt-3">
        <div className="col-lg-8 col-sm-12">
          <img src={ImageEU2} className="img-fluid"  alt="EUfonds2" />
        </div>
      </div>

      <div className="row">
        <div className="col-lg-8 col-sm-12">
          <p style={options[textSizeSelected].styleNormal}>Novo razvijene proizvode prije stavljanja na tržište potrebno je certificirati i uskladiti s normama te dokazati kvalitetu, sigurnost i pouzdanost proizvoda. U tu svrhu poduzeće se prijavilo na natječaj „Certifikacijom proizvoda do tržišta“.</p>
        </div>
      </div>

      <div className="row">
        <div className="col-lg-8 col-sm-12">
          <p style={options[textSizeSelected].styleNormal}>Nakon uspješne prijave projekta „Certificiranjem novih proizvoda poduzeća Pinky-S d.o.o. do povećanja konkurentnosti poduzeća“, poduzeće provodi projekt u razdoblju od 12. srpnja 2017. do 12. srpnja 2018. godine. Provedbom projekta certificirat će se osam vlastito razvijenih proizvoda - novih vrsta aditiva za transportni beton i beton za proizvodnju betonskih elemenata, eliminirajući problem nepostojanja certifikata i nemogućnost plasiranja novih proizvoda na tržište.</p>
        </div>
      </div>

      <div className="row mt-3">
        <div className="col-lg-8 col-sm-12">
          <img src={ImageEU3} className="img-fluid"  alt="EUfonds3" />
        </div>
      </div>

      <div className="row">
        <div className="col-lg-8 col-sm-12">
          <p style={options[textSizeSelected].styleNormal}>Cilj projekta je povećanje konkurentnosti na domaćem i inozemnim tržištima putem proširenja proizvodnog asortimana što će rezultirati uvođenjem osam novih certifikata za vlastite proizvode, povećanjem prihoda od prodaje za 11,46 % i povećanjem prihoda od izvoza za 6,13 %. Ukupna vrijednost projekta iznosi 119.584,66 HRK, dok iznos dobivenih bespovratnih sredstva iznosi 92.331,00 HRK.</p>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-8 col-sm-12">
          <p style={options[textSizeSelected].styleNormal}>U pripremi i provedbi projektnih aktivnosti sudjeluju vanjski stručnjaci iz REDEA-e koji posjeduju dugogodišnje iskustvo u pripremi i provođenju projekata, provođenju postupka javne nabave, kao i savjetovanju poduzetnika.</p>
        </div>
      </div>


      <div className="row mt-3">
        <div className="col-lg-8 col-sm-12">
          <p style={options[textSizeSelected].styleNormal}>Za sve dodatne informacije slobodno nam se obratite:</p>
        </div>
      </div>

      <div className="row mt-3">
        <div className="col-lg-8 col-sm-12">
          <p style={options[textSizeSelected].styleNormal}>Spomenka Koprivec, pinky-s@pinky-s.com, 040/ 360 700</p>
          <p style={options[textSizeSelected].styleNormal}>Kristina Pevec, kristina.pevec@redea.hr, 040/ 395 572</p>
        </div>
      </div>

      <div className="row mt-3">
        <div className="col-lg-8 col-sm-12">
          <p style={options[textSizeSelected].styleNormal}>Projekt je sufinancirala Europska unija iz Europskog fonda za regionalni razvoj.</p>
        </div>
      </div>

      <div className="row mt-3">
        <div className="col-lg-8 col-sm-12">
          <p style={options[textSizeSelected].styleNormal} onClick={() => openInNewTab('https://www.strukturnifondovi.hr')}> www.strukturnifondovi.hr</p>
        </div>
      </div>

      <div className="row mt-3">
        <div className="col-lg-8 col-sm-12">
          <p style={options[textSizeSelected].styleNormal} onClick={() => openInNewTab('https://strukturnifondovi.hr/eu-fondovi/esi-fondovi-2014-2020/op-konkurentnost-i-kohezija/')}>www.strukturnifondovi.hr/op-konkurentnost-i-kohezija-2014-2020-779</p>
        </div>
      </div>

      <div className="row mt-3">
        <div className="col-lg-8 col-sm-12">
          <img src={ImageEu} className="img-fluid"  alt="EUfonds" />
        </div>
      </div>
      <div className="row">
        <div className="col-lg-8 col-sm-12">
          <p style={options[textSizeSelected].styleSub}>01/2017.</p>
        </div>
      </div>



    </div>
  );
};

export default EUProjects;
