import React from 'react';
import {useFontSizeStore} from "../stores/useFontSizeStore";
import Image1 from "../assets/doziranje_kemijskih_dodataka.png";

const Automation7 = () => {

  const options = useFontSizeStore(state => state.defs)
  const textSizeSelected = useFontSizeStore(state => state.textSizeSelected)

  return (
    <>

      <img src={Image1} className="ml-auto mr-auto" style={{display: 'block', width: '80%'}} alt="Auto" />
      <div className="container">
        <div className="row">
          <div className="col-12">
            <p style={options[textSizeSelected].styleHead}>DOZIRANJE KEMIJSKIH DODATAKA</p>
          </div>
        </div>

        <div className="row mt-1">
          <div className="col-12">
            <p style={options[textSizeSelected].styleNormal}> Kada betonara ima starije tehničko i/ili software-sko rješenje na koje je nemoguće instalirati novu opremu ili program, ili su toliko stari da nije moguće automatizirati cijeli proces, Pinky-s Vam nudi nezavisni sustav doziranja koji se povezuje na bilo koju postojeću betonaru.</p>
          </div>
        </div>

      </div>
    </>
  );
};

export default Automation7;