import React from 'react';
import {useFontSizeStore} from "../stores/useFontSizeStore";
import Image1 from '../assets/mjerenje_vlage.png'
import Image5 from "../assets/pdf.jpeg";
import Pdf from "../assets/IMKO_radarski_vlagomjeri.pdf";

const Equipment6 = () => {

  const options = useFontSizeStore(state => state.defs)
  const textSizeSelected = useFontSizeStore(state => state.textSizeSelected)

  const Mailto = ({ email, subject = '', body = '', children }) => {
    let params = subject || body ? '?' : '';
    if (subject) params += `subject=${encodeURIComponent(subject)}`;
    if (body) params += `${subject ? '&' : ''}body=${encodeURIComponent(body)}`;

    return <a href={`mailto:${email}${params}`}>{children}</a>;
  };

  return (
    <>

      <div className="container">

        <img src={Image1} className="ml-auto mr-auto" style={{display: 'block', width: '80%'}} alt="Vlaga5" />

        <div className="row">
          <div className="col-8">
            <p style={options[textSizeSelected].styleHead}>MJERENJE VLAGE </p>
          </div>
        </div>

        <div className="row mt-1">
          <div className="col-12">
            <p style={options[textSizeSelected].styleNormal}>Kod rada u betonskoj industriji potrebno je veliku pozornost posvetiti vlagi agregata kao i gotovog betona. Određivanje vlage direktno utječe na količinu izmiješanog betona, konstantnu konzistenciju i pouzdani V/C faktor. O važnosti vlagomjera i konstantnosti V/C faktora možete pročitati ovdje.  </p>
          </div>
        </div>

        <img src={Image5} className="ml-auto mr-auto" style={{display: 'block', width: '80%'}} alt="Vlaga5" />

        <div className="row mt-0">
          <div className="col-12">
            <p style={options[textSizeSelected].styleNormal}>
              Vlagu najčešće mjerimo:<br/>
              • u spremištima materijala<br/>
              • na izlazu (ispustu) materijala<br/>
              • i u miješalici
            </p>
          </div>
        </div>


        <div className="row mt-0">
          <div className="col-12">
            <p style={options[textSizeSelected].styleNormal}>
              Sonde za mjerenje vlage u građevinarstvu moraju zadovoljiti različite zahtjeve za uporabu u pijesku, šljunku i svježem betonu. Pri usklađivanju s normama u proizvodnji transportnog, samozbijajućeg i drugih vrsta betona vlagomjeri sve više dobivaju na značenju. Stručnjaci za beton često su suočeni s pitanjem, ako i kako ti zahtjevi mogu biti ispunjeni. Sonda za mjerenje vlage temeljena na tehnologiji radara (TDR) zadovoljava te zahtjeve.
            </p>
          </div>
        </div>

        <div className="row mt-0">
          <div className="col-12">
            <p style={options[textSizeSelected].styleNormal}>
              Vlagomjer ima važnu ulogu u betonskoj industriji. Određivanje vlage u pojedinim agregatima se propisuje zakonski. Norma obvezuje proizvođača betona koji nema vlagomjer da se vlaga agregata određuje svaki dan dok se mjerenje vlage s vlagomjerom mora kontrolirati svakih 7 dana.
            </p>
          </div>
        </div>

        <div className="row mt-0">
          <div className="col-12">
            <p style={options[textSizeSelected].styleNormal}>
              Za detaljne informacije možete pogledati
              <a href = {Pdf} target = "_blank" rel="noopener noreferrer"> ovdje </a>
               članak o važnosti vlagomjera ili možete kontaktirati
              <Mailto email="pinky-s@pinky-s.com" subject="Info" body="">
               email: pinky-s@pinky-s.com
              </Mailto>
              kao i posjetiti proizvođača
              <a href="https://www.imko.de/en/moisture-in-bulk-solids/"> "IMKO" </a>
               na njegovoj web stranici.
            </p>
          </div>
        </div>

      </div>
    </>
  );
};

export default Equipment6;
