import React from 'react';
import {useFontSizeStore} from "../stores/useFontSizeStore";
import Image1 from "../assets/dispecer_sustav_m.jpeg";

const Automation4 = () => {

  const options = useFontSizeStore(state => state.defs)
  const textSizeSelected = useFontSizeStore(state => state.textSizeSelected)

  return (
    <>

      <img src={Image1} className="ml-auto mr-auto" style={{display: 'block', width: '100%'}} alt="Auto" />
      <div className="container">
        <div className="row">
          <div className="col-12">
            <p style={options[textSizeSelected].styleHead}>NADZORNO-OTPREMNIČKI SUSTAV BETONARA</p>
          </div>
        </div>

        <div className="row mt-1">
          <div className="col-12">
            <p style={options[textSizeSelected].styleNormal}>Rad s više pogona (betonara) nije moguć bez našeg nadzorno-otpremničkog sustava pomoću kojega možemo gledati "on-line" analizu pojedinog postrojenja, vršiti narudžbu materijala, a kod velikih projekata možemo i organizirati raspodjelu poslovanja (primljenih narudžbi) po pogonima.</p>
          </div>
        </div>

      </div>
    </>
  );
};

export default Automation4;