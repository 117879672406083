import React from 'react';
import {useFontSizeStore} from "../stores/useFontSizeStore";
import Image1 from "../assets/zeljezni_krom_oksid.png";
const PigmentsGreen = () => {

  const options = useFontSizeStore(state => state.defs)
  const textSizeSelected = useFontSizeStore(state => state.textSizeSelected)

  return (
    <div className="container">
      <div className="row">
        <div className="col-8">
          <p style={options[textSizeSelected].styleHead}>ZELENI KROM III OKSID</p>
        </div>
        <div className="col-4">

        </div>
      </div>

      <div className="row">
        <div className="col-lg-10 col-sm-12">
          <img src={Image1} className="ml-auto mr-auto" style={{display: 'block', width: '100%'}} alt="PigmentGreen" />
        </div>
      </div>

      <div className="row mt-3">
        <div className="col-12">
          <p style={options[textSizeSelected].styleHead}></p>
        </div>
      </div>

      <div className="row mt-1">
        <div className="col-lg-8 col-sm-12">
          <p style={options[textSizeSelected].styleNormal}>
            Ubraja se među najstarije anorganske pigmente. Zbog svojeg sastava, odlikuje se velikom tvrdoćom i otpornošću prema abraziji te visokoj temperaturi, inertnošću, postojanošću na svijetlo i prema djelovanju vode, kiselina, alkalija i drugih kemikalija. Ima široki spektar primjene, što znači da se može koristiti praktički u svim granama industrije.
            <br/>
            U svojoj ponudi nudimo Vam proizvod marke Elementis Chromium, najvećeg svjetskog proizvođača krom oksida.
            <br/>
            Model koji nudimo naziva se M-100.
          </p>
        </div>
        <div className="col-lg-4 col-sm-12">

        </div>
      </div>

      <div className="row mt-3">
        <div className="col-12">
          <p style={options[textSizeSelected].styleNormalBold}>Tehnički podaci: </p>
        </div>
      </div>

      <div className="row mt-1">
        <div className="col-lg-8 col-sm-12">
          <p style={options[textSizeSelected].styleNormal}>
            - Izgled: fini zeleni prah
            <br/>
            - Topljivost u vodi: netopljiv
            <br/>
            - Otpornost na temperaturu: potpuno otporan do 800°C, a tali se pri 2000°C
            <br/>
            - Otpornost na kiseline i lužine: odlična, 20% klorovodične kiseline ili 20% otopina natrijevog hidroksida nema nikakvog utjecaja na pigment.
            <br/>
            - Otpornost na otapala: potpuno otporan na sva otapala.
          </p>
        </div>
        <div className="col-lg-4 col-sm-12">

        </div>
      </div>


    </div>
  );
};

export default PigmentsGreen;
