import React from 'react';
import {useFontSizeStore} from "../stores/useFontSizeStore";
const Careers = () => {

  const options = useFontSizeStore(state => state.defs)
  const textSizeSelected = useFontSizeStore(state => state.textSizeSelected)

  return (
    <div className="container">
      <div className="row">
        <div className="col-8">
          <p style={options[textSizeSelected].styleOver}>Karijere</p>
        </div>
        <div className="col-4">

        </div>
      </div>

      <div className="row mt-3">
        <div className="col-12">
          <p style={options[textSizeSelected].styleHead}>AUTOMATIČAR</p>
        </div>
      </div>

      <div className="row mt-1">
        <div className="col-lg-8 col-sm-12">
          <p style={options[textSizeSelected].styleNormal}>
            Tražimo djelatnika u odjelu Automatizacije
            <br/>
            -znanje Siemens automatizacije
            <br/>
            -znanje Visual Studio VB.NET i(ili) C#
            <br/>
            -znanje MS SQL server
            <br/>
          </p>
        </div>
        <div className="col-lg-4 col-sm-12">

        </div>
      </div>

      <div className="row mt-3">
        <div className="col-12">
          <p style={options[textSizeSelected].styleHead}>GRAĐEVISNKI TEHNIČAR/LABORANT </p>
        </div>
      </div>

      <div className="row mt-1">
        <div className="col-lg-8 col-sm-12">
          <p style={options[textSizeSelected].styleNormal}>
            Tražimo djelatnika u odjelu Pigmenti/Aditivi
            <br/>
            - poznavanje tehnologije betona
            <br/>
          </p>
        </div>
        <div className="col-lg-4 col-sm-12">

        </div>
      </div>


      <div className="row mt-4">
        <div className="col-12">
          <p style={options[textSizeSelected].styleHead}>STRUČNE PRAKSE </p>
        </div>
      </div>

      <div className="row mt-1">
        <div className="col-lg-8 col-sm-12">
          <p style={options[textSizeSelected].styleNormal}>
            - za učenike i studente elektrotehničkih /mehatroničkih usmjerenja.
            <br/>
          </p>
        </div>
        <div className="col-lg-4 col-sm-12">

        </div>
      </div>

      <div className="row mt-5">
        <div className="col-12">
          <p style={options[textSizeSelected].styleHead}>Volite izazove? Zainteresirani ste za rad u dinamičnom okruženju? </p>
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <p style={options[textSizeSelected].styleHead}>Kontaktirajte nas </p>
        </div>
      </div>

    </div>
  );
};

export default Careers;
