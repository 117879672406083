import React from 'react';
import {useFontSizeStore} from "../stores/useFontSizeStore";
import Image1 from "../assets/kobalt_plavi.png";
const PigmentsCobalt = () => {

  const options = useFontSizeStore(state => state.defs)
  const textSizeSelected = useFontSizeStore(state => state.textSizeSelected)

  return (
    <div className="container">
      <div className="row">
        <div className="col-8">
          <p style={options[textSizeSelected].styleHead}>KOBALT PLAVI</p>
        </div>
        <div className="col-4">

        </div>
      </div>

      <div className="row">
        <div className="col-lg-10 col-sm-12">
          <img src={Image1} className="ml-auto mr-auto" style={{display: 'block', width: '100%'}} alt="PigmentCobalt" />
        </div>
      </div>

      <div className="row mt-1">
        <div className="col-lg-8 col-sm-12">
          <p style={options[textSizeSelected].styleNormal}>
            Zbog neprikladnosti Ultramarine pigmenata u betonskoj industriji, te kod aplikacije pri povećanim temperaturama, također postoji plavi pigment kojim možemo zadovoljiti i te zahtjeve.
            <br/>
            Kobalt blue je plavi pigment koji se sastoji od aluminijevog i kobaltovog oksida. On je produkt reakcije kalciniranja na visokoj temperaturi, te ga to čini veoma otpornim. Kobalt plavi pigmenti veoma su stabilni na kiseline i alkalije, te na temperaturu (više od 1000°C). Koristi se za iste namjene kao i Ultramarine, ali je za razliku od Ultramarine pigmenta veoma primjenljiv i u betonskoj, staklenoj i keramičkoj industriji. Jedino on omogućuje trajno obojenje „mase“ betona plavom bojom.
            <br/>
            U svojoj ponudi, nudimo Vam pigment Cobalt Blue 4500 također od Venator proizvođača pigmenata.
          </p>
        </div>
        <div className="col-lg-4 col-sm-12">

        </div>
      </div>



    </div>
  );
};

export default PigmentsCobalt;
