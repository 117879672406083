import logo from "../assets/logo4.png";
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCopyright } from '@fortawesome/free-solid-svg-icons'
import { faLinkedin } from '@fortawesome/free-brands-svg-icons'

const Footer =() => {

  const openInNewTab = (url) => {
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
    if (newWindow) newWindow.opener = null
  }

  const style1 = {color: '#828291', fontSize: '12px'}
  const linkedIN = 'https://www.linkedin.com/in/milivojkoprivec/'

  return (
    <footer>
      <div className="container-fluid mt-1">
        <div className="card mx-5">
          <div className="row mb-4 ">
            <div className="col-md-4 col-sm-11 col-xs-11">
              <div className="footer-text pull-left">
                <div className="d-flex">
                  <img src={logo} alt='logo...'/>
                </div>
                <p className="card-text">Tvrtka Pinky-s d.o.o. posluje u industriji betona, te industriji boja i lakova, za koje nudi široku paletu proizvoda i usluga.</p>
                <div className="social mt-2 mb-3">
                  <a href={linkedIN} style={{color: 'white'}}><FontAwesomeIcon icon={faLinkedin} /></a>
                </div>
              </div>
            </div>
            <div className="col-md-2 col-sm-1 col-xs-1 mb-2"></div>
            <div className="col-md-2 col-sm-4 col-xs-4">
              <h5 style={{color: 'white'}}>EU</h5>
              <ul>
                <li><Link to="/euprojects" style={{color: '#828291', fontSize: '16px'}}>EU projekti</Link></li>
              </ul>
            </div>
            <div className="col-md-2 col-sm-4 col-xs-4">
              <h5 style={{color: 'white'}}>Usluge</h5>
              <ul className="card-text">
                <li><Link to="/news" style={{color: '#828291', fontSize: '16px'}}>Novosti</Link></li>
                <li><Link to="/support" style={{color: '#828291', fontSize: '16px'}}>Podrška</Link></li>
                <li><Link to="/lastprojects" style={{color: '#828291', fontSize: '16px'}}>Istaknuti projekti</Link></li>
                <li><Link to="/careers" style={{color: '#828291', fontSize: '16px'}}>Posao</Link></li>
              </ul>
            </div>
            <div className="col-md-2 col-sm-4 col-xs-4">
              <h5 style={{color: 'white'}}>Pinky-s d.o.o.</h5>
              <ul className="card-text">
                <li><Link to="/aboutus" style={{color: '#828291', fontSize: '16px'}}>O nama</Link></li>
                <li><Link to="/certificates" style={{color: '#828291', fontSize: '16px'}}>Certifikati</Link></li>
                <li><Link to="/ourteam" style={{color: '#828291', fontSize: '16px'}}>Naš tim</Link></li>
                <li><Link to="/contacts" style={{color: '#828291', fontSize: '16px'}}>Kontakt</Link></li>
              </ul>
            </div>
          </div>
          <div className="divider mb-4"></div>
          <div className="row" style={{fontSize: '12px'}}>
            <div className="col-md-6 col-sm-6 col-xs-6">
              <div className="pull-left">
                <p onClick={() => openInNewTab('https://roboot.net')}><FontAwesomeIcon icon={faCopyright} /> 2021 roboot</p>
              </div>
            </div>
            <div className="col-md-6 col-sm-6 col-xs-6">
              <div className="pull-right mr-4 d-flex policy">
                <div className="mr-3"><Link to="/termsofuse" style={style1}>Uvjeti korištenja</Link></div>
                <div ><Link to="/cookiepolicy" style={style1}>Korištenje kolačića</Link></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;