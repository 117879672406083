import React from 'react';
import {useFontSizeStore} from "../stores/useFontSizeStore";


const Aditives2 = () => {

  const options = useFontSizeStore(state => state.defs)
  const textSizeSelected = useFontSizeStore(state => state.textSizeSelected)

  return (
    <>

      <div className="container">



        <div className="row mt-1">
          <div className="col-lg-12 col-sm-12">
            <p style={options[textSizeSelected].styleNormal}>
              <br/>
              Današnja proizvodnja betona nezamisliva je bez upotrebe kemijskih dodataka za beton. Kemijski dodaci direktno utječu na kvalitetu betona, neka svojstva betona  i smanjenje štetnih utjecaja na okoliš. Za različite namjene betona koristimo različite vrste kemijskih dodataka.<br/>
              Kemijski dodaci počinju se  koristiti 1931. godine kada su dodaci korišteni da bi se poboljšala neka svojstva betona.<br/><br/>
              Razvoj kemijskih dodataka kroz povijest omogućio je da se beton počinje koristiti sve više u građevinama različitih namjena.  Ozbiljnijim korištenjem kemijskih dodataka beton je značajno zamijenio čelik u konstrukcijama zahtjevnih objekata.<br/><br/>
              S obzirom da je prošao period duži od 100 godina, mnoga istraživanja propituju efikasnost betona u smislu trajnosti proizvoda, te se upotreba betona našla pred velikim izazovom.<br/><br/>
              Kemijski dodaci novih generacija mogu omogućiti svojstva betona koja će garantirati dugogodišnju trajnost betona u različitim uvjetima eksploatacije.<br/><br/>
              PINKY-S tim za  „istraživanje i razvoj“ postavio je za cilj  razvoj kemijskih dodatke koji će  djelovati na kvalitetu i trajnost betona, kao i na štetni utjecaj proizvodnje betona na okoliš.
            </p>
          </div>

        </div>



      </div>
    </>
  );
};

export default Aditives2;
