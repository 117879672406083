import React from 'react';
import {useFontSizeStore} from "../stores/useFontSizeStore";
import Image1 from "../assets/labos.png";

const Support = () => {

  const options = useFontSizeStore(state => state.defs)
  const textSizeSelected = useFontSizeStore(state => state.textSizeSelected)

  return (
    <div className="container">
      <div className="row">
        <div className="col-8">
          <p style={options[textSizeSelected].styleOver}>Podrška</p>
        </div>
        <div className="col-4">

        </div>
      </div>

      <div className="row">
        <div className="col-6">
          <img src={Image1}  style={{ width: '100%', height: '300px', padding:'0px'}} alt="Oprema1" />
        </div>
      </div>


      <div className="row mt-3">
        <div className="col-lg-8 col-sm-12">
          <p style={options[textSizeSelected].styleNormal}>
            Budući da je stručna tehnička podrška kupcima jedan od značajnih aspekata poslovanja, Pinky-s mnogo ulaže u razvoj. U vlastitom laboratoriju stručno i iskusno osoblje nadzire kvalitetu ulaznih sirovina i vlastitih proizvoda. Prate se najnovija tehnološka dostignuća te se kontinuirano poboljšavaju svojstva vlastitih proizvoda i razvijaju novi proizvodi..
          </p>
        </div>
        <div className="col-lg-4 col-sm-12">

        </div>
      </div>

      <div className="row mt-3">
        <div className="col-lg-8 col-sm-12">
          <p style={options[textSizeSelected].styleNormal}>

          </p>
        </div>
        <div className="col-lg-4 col-sm-12">

        </div>
      </div>




    </div>
  );
};

export default Support;
