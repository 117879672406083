import React from 'react';
import {useFontSizeStore} from "../stores/useFontSizeStore";
import Image from "../assets/slika_kontakti.png";

const Contacts = () => {

  const options = useFontSizeStore(state => state.defs)
  const textSizeSelected = useFontSizeStore(state => state.textSizeSelected)

  return (
    <div className="container">
      <div className="row">
        <div className="col-8">
          <p style={options[textSizeSelected].styleOver}>Kontakti</p>
        </div>
        <div className="col-4">

        </div>
      </div>

      <img src={Image} className="img-fluid" style={{width:'80%', height:'390px', paddingBottom:'20px'}} alt="Contacts" />

      <div className="row mt-3">
        <div className="col-lg-3 col-sm-4">
          <p style={options[textSizeSelected].styleNormal}>
            Direktor:
          </p>
        </div>
        <div className="col-lg-2 col-sm-4">
          <p style={options[textSizeSelected].styleNormal}>
            Milivoj Koprivec
          </p>
        </div>
        <div className="col-lg-2 col-sm-4">
          <p style={options[textSizeSelected].styleNormal}>
            091 \ 360 - 7001
          </p>
        </div>
      </div>

      <div className="row mt-3">
        <div className="col-lg-3 col-sm-4">
          <p style={options[textSizeSelected].styleNormal}>
            Računovodstvo i financije:
          </p>
        </div>
        <div className="col-lg-2 col-sm-4">
          <p style={options[textSizeSelected].styleNormal}>
            Spomenka Koprivec
          </p>
        </div>
        <div className="col-lg-2 col-sm-4">
          <p style={options[textSizeSelected].styleNormal}>
            091 \ 360 - 7002
          </p>
        </div>
      </div>

      <div className="row mt-5">
        <div className="col-lg-3 col-sm-4">
          <p style={options[textSizeSelected].styleNormal}>
            Laboratorij:
          </p>
        </div>
        <div className="col-lg-2 col-sm-4">
          <p style={options[textSizeSelected].styleNormal}>
            Danijel Šinko
          </p>
        </div>
        <div className="col-lg-2 col-sm-4">
          <p style={options[textSizeSelected].styleNormal}>
            091 \ 360 - 7006
          </p>
        </div>
      </div>

      <div className="row mt-1">
        <div className="col-lg-3 col-sm-4">
          <p style={options[textSizeSelected].styleNormal}>

          </p>
        </div>
        <div className="col-lg-2 col-sm-4">
          <p style={options[textSizeSelected].styleNormal}>
            Nataša Sekulić
          </p>
        </div>
        <div className="col-lg-2 col-sm-4">
          <p style={options[textSizeSelected].styleNormal}>
            091 \ 360 - 7005
          </p>
        </div>
      </div>

      <div className="row mt-5">
        <div className="col-lg-3 col-sm-4">
          <p style={options[textSizeSelected].styleNormal}>
            Odjel automatizacije:
          </p>
        </div>
        <div className="col-lg-2 col-sm-4">
          <p style={options[textSizeSelected].styleNormal}>
            Dejan Kralj
          </p>
        </div>
        <div className="col-lg-2 col-sm-4">
          <p style={options[textSizeSelected].styleNormal}>
            091 \ 360 - 7009
          </p>
        </div>
      </div>

      <div className="row mt-1">
        <div className="col-lg-3 col-sm-4">
          <p style={options[textSizeSelected].styleNormal}>

          </p>
        </div>
        <div className="col-lg-2 col-sm-4">
          <p style={options[textSizeSelected].styleNormal}>
            Jura Jurović
          </p>
        </div>
        <div className="col-lg-2 col-sm-4">
          <p style={options[textSizeSelected].styleNormal}>
            091 \ 360 - 7008
          </p>
        </div>
      </div>

      <div className="row mt-1">
        <div className="col-lg-3 col-sm-4">
          <p style={options[textSizeSelected].styleNormal}>

          </p>
        </div>
        <div className="col-lg-2 col-sm-4">
          <p style={options[textSizeSelected].styleNormal}>
            Matija Novak
          </p>
        </div>
        <div className="col-lg-2 col-sm-4">
          <p style={options[textSizeSelected].styleNormal}>
            091 \ 360 - 7003
          </p>
        </div>
      </div>


      <div className="row mt-5">
        <div className="col-lg-3 col-sm-4">
          <p style={options[textSizeSelected].styleNormal}>
            Komercijala:
          </p>
        </div>
        <div className="col-lg-2 col-sm-4">
          <p style={options[textSizeSelected].styleNormal}>
            Danijel Šinko
          </p>
        </div>
        <div className="col-lg-2 col-sm-4">
          <p style={options[textSizeSelected].styleNormal}>
            091 \ 360 - 7006
          </p>
        </div>
      </div>

      <div className="row mt-1">
        <div className="col-lg-3 col-sm-4">
          <p style={options[textSizeSelected].styleNormal}>

          </p>
        </div>
        <div className="col-lg-2 col-sm-4">
          <p style={options[textSizeSelected].styleNormal}>
            Milivoj Koprivec
          </p>
        </div>
        <div className="col-lg-2 col-sm-4">
          <p style={options[textSizeSelected].styleNormal}>
            091 \ 360 - 7001
          </p>
        </div>
      </div>

      <div className="row mt-1">
        <div className="col-lg-3 col-sm-4">
          <p style={options[textSizeSelected].styleNormal}>

          </p>
        </div>
        <div className="col-lg-2 col-sm-4">
          <p style={options[textSizeSelected].styleNormal}>
            Ivana Pejić Novak
          </p>
        </div>
        <div className="col-lg-2 col-sm-4">
          <p style={options[textSizeSelected].styleNormal}>
            091 \ 360 - 7004
          </p>
        </div>
      </div>


    </div>
  );
};

export default Contacts;
