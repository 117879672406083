import React from 'react';
import AboutUsImg from '../assets/onama.jpeg'
import {useFontSizeStore} from "../stores/useFontSizeStore";
import Video from "./Video";

const AboutUs = () => {

  const options = useFontSizeStore(state => state.defs)
  const textSizeSelected = useFontSizeStore(state => state.textSizeSelected)

  return (
    <div className="container">

      <div className="row">
        <div className="col-8">
          <p style={options[textSizeSelected].styleOver}>O nama</p>
        </div>
        <div className="col-4">

        </div>
      </div>

      <Video />

      <div className="row mt-3">
        <div className="col-lg-8 col-sm-12">
          <p style={options[textSizeSelected].styleNormal}>
            Tvrtka Pinky-s d.o.o. osnovana je 1993. godine. Posluje u industriji betona, te industriji boja i lakova, za koje nudi široku paletu proizvoda i usluga. U cilju maksimalnog zadovoljenja potreba naših kupaca, 2003. godine Pinky-s d.o.o. u poslovanje uvodi međunarodni standard kvalitete ISO 9001:2015. Razvojem vlastite proizvodnje Pinky-s d.o.o. počinje uviđati sve značajnije potrebe za očuvanjem okoliša, pa 2004. godine u poslovanje uvodi i međunarodni standard ISO 14001:2015, u cilju odgovornog poslovanja prema okolišu te kontroliranom zbrinjavanju otpada.
            <br/>
            Kontinuirano ulaganje u razvoj, edukaciju naših zaposlenika, kao i u vlastiti laboratorij za kontrolu kvalitete ulaznih sirovina i vlastitih proizvoda, jamstvo su kvalitete isporučenih proizvoda i usluga.
          </p>
        </div>
        <div className="col-lg-4 col-sm-12">
          <img src={AboutUsImg} className="img-fluid"  alt="About Us" />
        </div>
      </div>

      <div className="row mt-3">
        <div className="col-lg-8 col-sm-12">
          <p style={options[textSizeSelected].styleNormal}>

          </p>
        </div>
      </div>

      <div className="row mt-3">
        <div className="col-lg-8 col-sm-12">
          <p style={options[textSizeSelected].styleNormal}>
            Obratite nam se s povjerenjem!
          </p>
        </div>
      </div>

    </div>
  );
};

export default AboutUs;
