import React from 'react';
import {useFontSizeStore} from "../stores/useFontSizeStore";

const Automation = () => {

  const options = useFontSizeStore(state => state.defs)
  const textSizeSelected = useFontSizeStore(state => state.textSizeSelected)

  return (
    <>

      <div className="container">


        <div className="row mt-1">
          <div className="col-lg-10 col-sm-12">
            <p style={options[textSizeSelected].styleNormal}>
              Automatika je u cjelosti projektirana i izrađena u PINKY-S d.o.o. Za brže rješavanje mogućih problema svaka automatizirana betonara povezana je internetom ili VPN mrežom. Svaka automatizirana betonara ima i 24 satnu programsku podršku, a ako treba možemo pomoći i savjetom oko mehaničkih problema vezanih za nju.
            </p>
          </div>
          <div className="col-lg-2 col-sm-12">

          </div>
        </div>

        <div className="row mt-3">
          <div className="col-1">

          </div>

          <ul className="list-group list-group-flush col-8">
            <li className="list-group-item">
              <p style={options[textSizeSelected].styleNormal}>
                • program
              </p>
            </li>
            <li className="list-group-item">
              <p style={options[textSizeSelected].styleNormal}>
                • mehanika
              </p>
            </li>
            <li className="list-group-item">
              <p style={options[textSizeSelected].styleNormal}>
                • automatika
              </p>
            </li>
          </ul>
        </div>

        <div className="row mt-1">
          <div className="col-lg-10 col-sm-12">
            <p style={options[textSizeSelected].styleNormal}>
              Pri rješavanju Vaših tehnoloških problema PINKY-S koristi vlastite inovacije, program i opremu čime pomaže poboljšanju kvalitete i/ili brzini izrade Vaših gotovih proizvoda. U suradnji sa svojim poslovnim partnerima PINKY-S obavlja i servis betonara te strojeva i uređaja za rad s pigmentima i kemijskim dodacima.
            </p>
          </div>
          <div className="col-lg-2 col-sm-12">

          </div>
        </div>

      </div>
    </>
  );
};

export default Automation;
