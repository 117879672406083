import React from 'react';
import {useFontSizeStore} from "../stores/useFontSizeStore";
import Image1 from '../assets/disolveri_mesalice.png'

const Equipment9 = () => {

  const options = useFontSizeStore(state => state.defs)
  const textSizeSelected = useFontSizeStore(state => state.textSizeSelected)

  return (
    <>

      <div className="container">
        <img src={Image1} className="ml-auto mr-auto" style={{display: 'block', width: '80%'}} alt="Oprema8" />

        <div className="row">
          <div className="col-8">
            <p style={options[textSizeSelected].styleHead}>DISOLVERI, MJEŠALA, MLINOVI... </p>
          </div>
        </div>

        <div className="row mt-1">
          <div className="col-12">
            <p style={options[textSizeSelected].styleNormal}>
              Pinky-s ima u svojoj ponudi opremu koja se koristi u industriji boje i lakova, te farmaceutskoj industriji, a proizvedena je u našoj partner firmi sa sjedištem u Kini. S obzirom da je već prošlo nekoliko godina od prvog takvog stroja na našem tržištu, možemo reći da su zadovoljili svojom kvalitetom i funkcionalnošću.
              <br/>
              Do sada je Pinky-s instalirao više od 200 strojeva iz te proizvodnje i za njih strojeve Pinky-s pruža vlastiti servis i rezervne dijelove. To su sljedeći strojevi:
              <br/>
              • horizontalni mlinovi<br/>
              • vertikalni mlinovi<br/>
              • disolveri<br/>
              • shakeri<br/>
              • dispenzeri<br/>
              • mješala ...
            </p>
          </div>
        </div>

      </div>
    </>
  );
};

export default Equipment9;
