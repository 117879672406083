const autoHR = {
  menu1: 'TRANSPORTNI BETON',
  menu2: 'NADZORNO-OTPREMNIČKI SUSTAV BETONARA',
  menu3: 'BETONSKE KONSTRUKCIJE I GALANTERIJA',
  menu4: 'PRIPREMA I DOZIRANJE DISPERZIJE PIGMENATA',
  menu5: 'DOZIRANJE KEMIJSKIH DODATAKA',
  menu6: 'KALIBRACIJA VLAGOMJERA',
  menu7: 'SOFTWARE MOSNA VAGA',
  menu8: 'PUNILICA BOJE',
  menu9: 'PROGRAMSKE ANALIZE',
  menu10: 'REFERENCE AUTOMATIKA',
}

export default autoHR