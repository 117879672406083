import React from 'react';
import {useFontSizeStore} from "../stores/useFontSizeStore";
import Image1 from "../assets/proizvodnja_boja.png";
import Image2 from "../assets/rsz_eko_kuca.jpeg";
import Image3 from "../assets/rsz_djecji_vrtic.jpeg";

const PigmentsSpecials = () => {

  const options = useFontSizeStore(state => state.defs)
  const textSizeSelected = useFontSizeStore(state => state.textSizeSelected)

  return (
    <div className="container">
      <div className="row">
        <div className="col-12">
          <p style={options[textSizeSelected].styleHead}>PROIZVODNJA BOJA SPECIJALNE NAMJENE</p>
        </div>
      </div>

      <div className="row">
        <div className="col-lg-10 col-sm-12">
          <img src={Image1} className="ml-auto mr-auto" style={{display: 'block', width: '100%'}} alt="Pigmenti1" />
        </div>
      </div>

      <div className="row mt-1">
        <div className="col-sm-12">
          <p style={options[textSizeSelected].styleNormal}>
            Na temelju dugogodišnjeg iskustva u radu s pigmentima, PINKY-S razvija vlastitu proizvodnju boja specijalne namjene. Posljednjih je godina u tom pogledu postignut velik napredak tako da PINKY-S danas snabdijeva jedno od najvećih poduzeća za proizvodnju ukrasne keramike u Europi.
            <br/>
            Kad je na tržištu primijećena potreba za kvalitetnim premazom za betonski crijep, PINKY-S je krenuo i u tom smjeru. Osim kvalitete premaza, ovdje je izuzetno važna i kakvoća aplikacije. Svojim iskustvom i znanjem, Pinky-s je u mogućnosti pomoći i najzahtjevnijem kupcu.
          </p>
        </div>
      </div>

      <div className="row">
        <div className="col-lg-10 col-sm-12">
          <img src={Image2} className="ml-auto mr-auto" style={{display: 'block', width: '100%'}} alt="PigmentSpecials2" />
        </div>
      </div>
      <div className="row m-0 p-0">
        <div className="col-sm-12 m-0 p-0">
          <p style={options[textSizeSelected].styleNormal}>
            BSB boja za beton.
          </p>
        </div>
      </div>

      <div className="row">
        <div className="col-lg-10 col-sm-12">
          <img src={Image3} className="ml-auto mr-auto" style={{display: 'block', width: '100%'}} alt="PigmentSpecials3" />
        </div>
      </div>
      <div className="row m-0 p-0">
        <div className="col-sm-12 m-0 p-0">
          <p style={options[textSizeSelected].styleNormal}>
            EFFLOTEC PY10 premaz.
          </p>
        </div>
      </div>


    </div>
  );
};

export default PigmentsSpecials;
