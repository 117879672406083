import React from 'react';
import {useFontSizeStore} from "../stores/useFontSizeStore";
import Image1 from '../assets/doziranje_dodatka_za_beton.png'

const Equipment3 = () => {

  const options = useFontSizeStore(state => state.defs)
  const textSizeSelected = useFontSizeStore(state => state.textSizeSelected)

  return (
    <>

      <div className="container">
        <img src={Image1} className="ml-auto mr-auto" style={{display: 'block', width: '80%'}} alt="Oprema1" />


        <div className="row">
          <div className="col-12">
            <p style={options[textSizeSelected].styleHead}>DOZIRANJE DODATAKA ZA BETON </p>
          </div>
        </div>

        <div className="row mt-1">
          <div className="col-12">
            <p style={options[textSizeSelected].styleNormal}> Dodaci u betonskoj industriji se koriste da poboljšaju svojstva betona. Naša oprema Vam omogućuje doziranje do 4 različita aditiva u dvije posude. Kod doziranja treba voditi brigu da ne dođe do njihove međusobne kemijske reakcije te smanjenja njihove efikasnosti ili pak do neefikasnosti. Vođeni našim iskustvom konstruirali smo opremu kao i software koji nude najbolje rješenje za Vas uz minimalni gubitak.</p>
          </div>
        </div>


        <div className="row mt-0">
          <div className="col-12">
            <p style={options[textSizeSelected].styleNormal}>
              • vaga za aditive ili vaga aditiva ili vaga za aditiv<br/>
              • vaga za boje ili vaga boje<br/>
              • vaga za dodatke<br/>
            </p>
          </div>
        </div>


      </div>
    </>
  );
};

export default Equipment3;
