import React from 'react';
import {useFontSizeStore} from "../stores/useFontSizeStore";
import Image1 from '../assets/doziranje_pigmenta.png'

const Equipment4 = () => {

  const options = useFontSizeStore(state => state.defs)
  const textSizeSelected = useFontSizeStore(state => state.textSizeSelected)

  return (
    <>

      <div className="container">
        <div className="row">

          <div className="col-12 mr-1 pr-1">
            <img src={Image1}  style={{ width: '100%', height: '200px', padding:'0px'}} alt="Oprema1" />
          </div>

        </div>


        <div className="row">
          <div className="col-8">
            <p style={options[textSizeSelected].styleHead}>DOZIRANJE PIGMENATA </p>
          </div>
        </div>

        <div className="row mt-1">
          <div className="col-12">
            <p style={options[textSizeSelected].styleNormal}>
              Vrsta pigmenta određuje način primjene i doziranja:<br/>
              • praškasti<br/>
              • granulasti ili<br/>
              • tekući pigment<br/>
            </p>
          </div>
        </div>


        <div className="row mt-0">
          <div className="col-12">
            <p style={options[textSizeSelected].styleNormal}>Pinky-s Vam nudi rješenje za doziranje svih vrsta pigmenata, za mala, srednja i velika postrojenja. Specijalizirali smo se za tekuće pigmente zbog njihove bolje distribucije u sam materijal (beton).  </p>
          </div>
        </div>


        <div className="row mt-0">
          <div className="col-12">
            <p style={options[textSizeSelected].styleNormal}>
              Doziranje tekućih pigmenata zavisi od količine cementa, materijala i vlažnosti. Imamo rješenje za najzahtjevnije korisnike s mogućnošću doziranja do 8 boja ili 4 boje na 2 betonare na 1 vagi. Cijeli sustav doziranja i miješanja boja je konstruiran i izrađen u Pinky-s d.o.o.
              <br/>
              • vaga za aditive ili vaga aditiva ili vaga za aditiv<br/>
              • vaga za boje ili vaga boje<br/>
              • vaga za dodatke<br/>
            </p>
          </div>
        </div>

      </div>
    </>
  );
};

export default Equipment4;
