import React from 'react';
import {useFontSizeStore} from "../stores/useFontSizeStore";
import Image1 from "../assets/crijep 1_m.jpeg";
import Image2 from "../assets/crijep 2_m.jpeg";
import Image3 from "../assets/crijep 4_m.jpeg";
import Image4 from "../assets/crijep 3_m.jpeg";
import Image5 from "../assets/crijep 5_m.jpeg";
const PigmentsRoofTiles = () => {

  const options = useFontSizeStore(state => state.defs)
  const textSizeSelected = useFontSizeStore(state => state.textSizeSelected)

  return (
    <div className="container">
      <div className="row">
        <div className="col-12">
          <p style={options[textSizeSelected].styleOver}>BOJA ZA BETONSKI CRIJEP</p>
        </div>
      </div>

      <div className="row m-0 p-0">
        <div className="col-lg-10 col-sm-12 m-0-p-0">
          <img src={Image1} className="ml-auto mr-auto" style={{display: 'block', width: '80%'}} alt="RoofTiles1" />
        </div>
      </div>
      <div className="row m-0 p-0">
        <div className="col-lg-10 col-sm-12 m-0-p-0">
          <img src={Image2} className="ml-auto mr-auto" style={{display: 'block', width: '80%'}} alt="RoofTiles1" />
        </div>
      </div>
      <div className="row m-0 p-0">
        <div className="col-lg-10 col-sm-12 m-0-p-0">
          <img src={Image3} className="ml-auto mr-auto" style={{display: 'block', width: '80%'}} alt="RoofTiles1" />
        </div>
      </div>
      <div className="row m-0 p-0">
        <div className="col-lg-10 col-sm-12 m-0-p-0">
          <img src={Image4} className="ml-auto mr-auto" style={{display: 'block', width: '80%'}} alt="RoofTiles1" />
        </div>
      </div>
      <div className="row m-0 p-0">
        <div className="col-lg-10 col-sm-12 m-0-p-0">
          <img src={Image5} className="ml-auto mr-auto" style={{display: 'block', width: '80%'}} alt="RoofTiles1" />
        </div>
      </div>


      <div className="row mt-1">
        <div className="col-12">
          <p style={options[textSizeSelected].styleNormal}>
            Kad je na tržištu primijećena potreba za kvalitetnim premazom za betonski crijep, PINKY-S je krenuo i u tom smjeru. Prilikom razvoja naših boja, posebna pažnja posvećena je samoj kvaliteti korištenih sirovina. Važno je spomenuti da se u proizvodnji naših boja za betonski crijep koriste isključivo čisto-akrilatna veziva, te hidrofobna punila i naravno, pigmenti na bazi sintetičkih željeznih oksida. Samim tim, postignuta je izuzetna otpornost boje na utjecaj kiselina i lužina, te apsolutna svjetlostabilnost, što je pri uvjetima upotrebe samoga crijepa od presudne važnosti.
            <br/>
            Osim kvalitete samog premaza, kod proizvodnje obojenog betonskog crijepa, izuzetno je važna i kakvoća aplikacije samog premaza. Svojim iskustvom i znanjem, PINKY-S je u mogućnosti pomoći i najzahtjevnijem kupcu.
            <br/>
            U našem asortimanu nudimo sljedeće standardne nijanse:
            <br/>
            - Boja za betonski crijep NARANČASTA
            <br/>
            - Boja za betonski crijep CRVENA
            <br/>
            - Boja za betonski crijep SMEĐA
            <br/>
            - Boja za betonski crijep BORDO (vinsko crvena)
            <br/>
            - Boja za betonski crijep ANTRACIT
          </p>
        </div>
      </div>


    </div>
  );
};

export default PigmentsRoofTiles;
