import React from 'react';
import {useFontSizeStore} from "../stores/useFontSizeStore";
import Image1 from '../assets/automatsko_nanosenje.png'
import Image3 from '../assets/linija za bojanje1_300.jpeg'
import Image4 from "../assets/linija za bojanje2_300.jpeg";

const Equipment7 = () => {

  const options = useFontSizeStore(state => state.defs)
  const textSizeSelected = useFontSizeStore(state => state.textSizeSelected)

  return (
    <>

      <div className="container">

        <img src={Image1} className="ml-auto mr-auto" style={{display: 'block', width: '80%'}} alt="Vlaga5" />

        <div className="row">
          <div className="col-12">
            <p style={options[textSizeSelected].styleHead}>AUTOMATSKO NANOŠENJE PREMAZA </p>
          </div>
        </div>


        <div className="row mt-2">
          <div className="col-12">
            <p style={options[textSizeSelected].styleNormalBold}>Airless sistem za nanošenje zaštitnih premaza na betonske proizvode </p>
          </div>
        </div>

        <div className="row mt-0">
          <div className="col-12">
            <p style={options[textSizeSelected].styleNormal}>
              Sistem je prije svega namijenjenih nanošenju zaštitnih premaza u proizvodnji betonskih opločnika i betonskih ploča. Moguće ga je postaviti na poziciju odmah nakon izlaska iz preše ili na poziciju prije pakiranja.<br/>
              Mogućnost ovisi o ostalim zahtjevima koje proizvod i materijal moraju zadovoljiti.
              Airless sistem namijenjenih je isključivo za nanošenje materijala koji ne sadrže otapala.  Zbog zaštite radne okoline i smanjenog rasipa materijala sistem radi na malom radnom pritisku bez potrebnog zraka za raspršivanje.  Sistem je univerzalni, ali konstrukcija ovisi o tipu proizvodnje i najboljoj poziciji u liniji proizvodnje.
            </p>
          </div>
        </div>

        <div className="row mt-1">
          <div className="col-12">
            <p style={options[textSizeSelected].styleNormalBold}>Karakteristike sistema: </p>
          </div>
        </div>

        <div className="row mt-0">
          <div className="col-12">
            <p style={options[textSizeSelected].styleNormal}>
              - Radni tlak 2-4 bara<br/>
              - Širina nanošenja 120 cm<br/>
              - Količina nanošenja regulira se veličinom dizne<br/>
              - Isporučuju se tri kompeta leptir dizni<br/>
              - S obzirom da je zatvoren sistem nema zasušivanja u cjevovodu<br/>
              - Sistem za automatsko pranje dizni za vrijeme pauze<br/>
              - U sistemu su 4 (četri) airless pištolja za nanos sa mogućnošću radnog tlaka do 210 bara<br/>
              - Jednostavan ručni sistem za postavljanje širine nanošenja<br/>
              - Električno pozicioniranje visine stroja<br/>
              - Pumpa je opremljena sa sistemom za smanjenje pulsiranja<br/>
              - Leptir dizne omogućuju brzu promjenu i eventualno čišćenje sa okretanje leptira<br/>
              - Automatsko upravljanje sa početkom i krajem nanošenja pomoću PLC-a i panela za upravljanje<br/>
            </p>
          </div>
        </div>

        <div className="row mt-5">
          <div className="col-12">
            <p style={options[textSizeSelected].styleNormalBold}>Akrilni pemazi za betonski crijep </p>
          </div>
        </div>

        <div className="row mt-0">
          <div className="col-12">
            <p style={options[textSizeSelected].styleNormal}>
              Nanošenje akrilnih premaza na betonski crijep zahtjevni je proces, i upravo iz tog razloga svojim smo kupcima smo htjeli pomoći da kvalitetno apliciraju naš akrilni premaz. Iskustvo nam je pomoglo da kvalitetno riješimo problem nanošenja akrilnog premaza, a rad na projektu stroja za nanošenje omogućio nam je da još bolje projektiramo naš premaz za betonski crijep. Preuzimajući odgovornost za premaz i nanošenje premaza naš kupac je siguran u kvalitetu svog proizvoda.
            </p>
          </div>
        </div>

        <div className="row">
          <div className="col-lg-1">

          </div>
          <div className="col-lg-5 col-12 mr-2 pr-2">
            <img src={Image3}  style={{ width: '100%', height: '400px', padding:'0px'}} alt="Bojanje3" />
          </div>
          <div className="col-lg-5 col-12 ml-2 pl-2">
            <img src={Image4}  style={{ width: '100%', height:'400px', padding:'0px'}} alt="Bojanje4" />
          </div>
          <div className="col-lg-1">

          </div>
        </div>


        <div className="row mt-0">
          <div className="col-12">
            <p style={options[textSizeSelected].styleNormal}>
              Komore za špricanje opremljene su filterima za oprašivanje, a nanos premaza može biti:<br/>

              - airless<br/>
              - airmix<br/>
              <br/>
              Osim za nanošenje premaza na betonski crijep projektirali smo i izradili stroj za:<br/>
              - nanošenje impregnacije na betonske opločnike nakon izlaska iz stroja i<br/>
              - stroj za impregnaciju paleta pri izradi opločnka
            </p>
          </div>
        </div>

      </div>
    </>
  );
};

export default Equipment7;
