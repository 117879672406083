import React from 'react';
import {useFontSizeStore} from "../stores/useFontSizeStore";
import Image1 from "../assets/transportni_beton.jpeg";

const Automation3 = () => {

  const options = useFontSizeStore(state => state.defs)
  const textSizeSelected = useFontSizeStore(state => state.textSizeSelected)

  return (
    <>
      <img src={Image1} className="ml-auto mr-auto" style={{display: 'block', width: '100%'}} alt="Auto" />
      <div className="container">
        <div className="row">
          <div className="col-12">
            <p style={options[textSizeSelected].styleHead}>TRANSPORTNI BETON</p>
          </div>
        </div>

        <div className="row mt-1">
          <div className="col-12">
            <p style={options[textSizeSelected].styleNormal}>Transportni beton se koristi kod raznih projekata kuća, poslovnih objekata, zgrada te podzemnih objekata i svaki takav projekat traži drugačiju vrstu betona. Naš program Vam nudi jednostavnost, ali i potpunost. Sve što trebate jest:
            <br/>
              • odabrati recepturu<br/>
              • upisati količinu koju trebate i miješanje može početi
            </p>
          </div>
        </div>

      </div>
    </>
  );
};

export default Automation3;