import React from 'react';
import {useFontSizeStore} from "../stores/useFontSizeStore";
import Image1 from "../assets/doziranje_300.jpeg";

const Automation6 = () => {

  const options = useFontSizeStore(state => state.defs)
  const textSizeSelected = useFontSizeStore(state => state.textSizeSelected)

  return (
    <>

      <img src={Image1} className="ml-auto mr-auto" style={{display: 'block', width: '80%'}} alt="Auto" />
      <div className="container">
        <div className="row">
          <div className="col-12">
            <p style={options[textSizeSelected].styleHead}>PRIPREMA I DOZIRANJE DISPERZIJE PIGMENATA</p>
          </div>
        </div>

        <div className="row mt-1">
          <div className="col-12">
            <p style={options[textSizeSelected].styleNormal}>Prikazan software upravlja automatikom miješanja i doziranja 5 različitih tekućih pigmenata. Postoje rješenja trenutno do maksimalno 8 tekućih pigmenta ili 4 tekuća pigmenta na 2 betonare na 1 vagi.</p>
          </div>
        </div>

      </div>
    </>
  );
};

export default Automation6;