import React from 'react';
import {useFontSizeStore} from "../stores/useFontSizeStore";
import Image1 from '../assets/granumin.jpeg'

const Equipment5 = () => {

  const options = useFontSizeStore(state => state.defs)
  const textSizeSelected = useFontSizeStore(state => state.textSizeSelected)

  return (
    <>

      <div className="container">
        <img src={Image1} className="ml-auto mr-auto" style={{display: 'block', width: '80%'}} alt="Oprema1" />

        <div className="row">
          <div className="col-8">
            <p style={options[textSizeSelected].styleHead}>DOZIRANJE GRANULA </p>
          </div>
        </div>

        <div className="row mt-1">
          <div className="col-12">
            <p style={options[textSizeSelected].styleNormal}>Pinky-s d.o.o. je izradio uređaj za doziranje pigmenta u granulama, Granumin. Granumin ima najveću grešku od 10 grama u stvarnim uvjetima rada što je dovoljno da zadovolji potrebe svakog proizvođača betonske galanterije.  </p>
          </div>
        </div>



      </div>
    </>
  );
};

export default Equipment5;
