import React from 'react';

const Carousel = () => {
  return (
    <div id="carouselExampleIndicators" className="carousel slide" data-ride="carousel">
      <ol className="carousel-indicators">
        <li data-target="#carouselExampleIndicators" data-slide-to="0" className="active"></li>
        <li data-target="#carouselExampleIndicators" data-slide-to="1"></li>
        <li data-target="#carouselExampleIndicators" data-slide-to="2"></li>
        <li data-target="#carouselExampleIndicators" data-slide-to="3"></li>
      </ol>
      <div className="carousel-inner">
        <div className="carousel-item active">
          <div className="item slide1">
            <div className="container">
              <div className="carousel-caption">
                <h1>PIGMENTI</h1>
                <p>Izrazite se bojom</p>
              </div>
            </div>
          </div>
        </div>

        <div className="carousel-item">
            <div className="item slide2">
              <div className="container">
                <div className="carousel-caption">
                  <h1>ADITIVI</h1>
                  <p>„Kap“ koja čini razliku</p>
                </div>
              </div>
            </div>
        </div>

        <div className="carousel-item">
            <div className="item slide3">
              <div className="container">
                <div className="carousel-caption">
                  <h1>OPREMA</h1>
                  <p>Preciznost je „ključ“</p>
                </div>
              </div>
            </div>
        </div>

        <div className="carousel-item">
            <div className="item slide4">
              <div className="container">
                <div className="carousel-caption">
                  <h1>AUTOMATIKA</h1>
                  <p>Znanje u službi automatike</p>
                </div>
              </div>
            </div>
        </div>
      </div>
      <a className="carousel-control-prev" href="#carouselExampleIndicators" role="button" data-slide="prev">
        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
        <span className="sr-only">Previous</span>
      </a>
      <a className="carousel-control-next" href="#carouselExampleIndicators" role="button" data-slide="next">
        <span className="carousel-control-next-icon" aria-hidden="true"></span>
        <span className="sr-only">Next</span>
      </a>
    </div>
  );
};

export default Carousel;
