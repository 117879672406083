import React, {useState} from 'react';
import Image from '../assets/oprema.png'
import {useFontSizeStore} from "../stores/useFontSizeStore";
import Equipment2 from "./Equipment2";
import Equipment3 from "./Equipment3";
import Equipment4 from "./Equipment4";
import Equipment5 from "./Equipment5";
import Equipment6 from "./Equipment6";
import Equipment7 from "./Equipment7";
import Equipment9 from "./Equipment9";
import Image1 from "../assets/plastifikator1.jpeg";

const Equipment = () => {

  const options = useFontSizeStore(state => state.defs)
  const textSizeSelected = useFontSizeStore(state => state.textSizeSelected)
  const [option, SetOption] = useState(1)

  return (
    <>
      <img src={Image} className="ml-auto mr-auto" style={{display: 'block',height:'600px',  width: '100%'}} alt="Plastifikator" />
      <div className="container">
        
        <div className="row">
          <div className="col-lg-4 col-sm-12">

          </div>
          <div className="col-lg-8 col-sm-12">
            <button type="button" className="btn btn-link mt-0 mb-0 pt-0 pb-0" onClick={() => SetOption(1)}>
             <p style={options[textSizeSelected].styleOver}>Oprema</p>
            </button>
          </div>
        </div>

        <div className="row mt-1">
          <div className="col-lg-4 col-sm-12">
            <ul className="list-group list-group-flush">
              <li className="list-group-item m-0 p-0">
                <button type="button" className="btn btn-link text-left m-0 p-0" onClick={() => SetOption(2)}><p style={options[textSizeSelected].styleNormalBold}>DOZIRANJE DODATAKA ZA BETON</p></button>
              </li>
              <li className="list-group-item m-0 p-0">
                <button type="button" className="btn btn-link text-left m-0 p-0" onClick={() => SetOption(3)}><p style={options[textSizeSelected].styleNormalBold}>DOZIRANJE PIGMENATA</p></button>
              </li>
              <li className="list-group-item m-0 p-0">
                <button type="button" className="btn btn-link text-left m-0 p-0" onClick={() => SetOption(4)}><p style={options[textSizeSelected].styleNormalBold}>DOZIRANJE GRANULA</p></button>
              </li>
              <li className="list-group-item m-0 p-0">
                <button type="button" className="btn btn-link text-left m-0 p-0" onClick={() => SetOption(5)}><p style={options[textSizeSelected].styleNormalBold}>MJERENJE VLAGE</p></button>
              </li>
              <li className="list-group-item m-0 p-0">
                <button type="button" className="btn btn-link text-left m-0 p-0" onClick={() => SetOption(6)}><p style={options[textSizeSelected].styleNormalBold}>AUTOMATSKO NANOŠENJE PREMAZA</p></button>
              </li>
              <li className="list-group-item m-0 p-0">
                <button type="button" className="btn btn-link text-left m-0 p-0" onClick={() => SetOption(8)}><p style={options[textSizeSelected].styleNormalBold}>DISOLVERI, MJEŠALA, MLINOVI...</p></button>
              </li>
            </ul>

          </div>
          <div className="col-lg-8 col-sm-12">
            {option === 1 && <Equipment2 />}
            {option === 2 && <Equipment3 />}
            {option === 3 && <Equipment4 />}
            {option === 4 && <Equipment5 />}
            {option === 5 && <Equipment6 />}
            {option === 6 && <Equipment7 />}
            {option === 8 && <Equipment9 />}
          </div>
        </div>

      </div>
    </>
  );
};

export default Equipment;
