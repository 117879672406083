import Image1 from '../assets/Binis.jpg'
import Image2 from '../assets/Zagorje_tehnobeton.jpg'
import Image3 from '../assets/Mi-blok.jpg'
import Image4 from '../assets/Bossin.jpg'
import Image5 from '../assets/brod.jpg'
import Image6 from '../assets/kazaliste.jpg'

const LastProjects = () => {
  return (
    <div className="container mt-3">
      <h2>Istaknuti projekti</h2>

      <div className="row row-cols-1 row-cols-md-3 g-4">
        <div className="col">
          <div className="card h-100">
            <img src={Image1} className="card-img-top" alt="BINIS doo" />
              <div className="card-body">
                <h5 className="card-title">BINIS d.o.o. - TORANJSKA BETONARA</h5>
                <p className="card-text">Upravljanje betonarom i software za praćenje proizvodnje betona</p>
              </div>
              <div className="card-footer">
                <small className="text-muted">.</small>
              </div>
          </div>
        </div>
        <div className="col">
          <div className="card h-100">
            <img src={Image2} className="card-img-top" alt="ZAGORJE TEHNOBETON dd"/>
              <div className="card-body">
                <h5 className="card-title">ZAGORJE TEHNOBETON d.d. – TORANJSKA BETONARA</h5>
                <p className="card-text">Upravljanje betonarom i software za praćenje proizvodnje betona.</p>
              </div>
              <div className="card-footer">
                <small className="text-muted">.</small>
              </div>
          </div>
        </div>
        <div className="col">
          <div className="card h-100">
            <img src={Image3} className="card-img-top" alt="..." />
              <div className="card-body">
                <h5 className="card-title">MI-BLOK – LINIJA ZA PROIZVODNJU BETONSKIH ELEMENATA</h5>
                <p className="card-text">Kompletna rekonstrukcija automatskog upravljanja linijom</p>
              </div>
              <div className="card-footer">
                <small className="text-muted">.</small>
              </div>
          </div>
        </div>
      </div>
      <div className="row row-cols-1 row-cols-md-3 g-4 mt-3">
        <div className="col">
          <div className="card h-100">
            <img src={Image4} className="card-img-top" alt="REGALNO SKLADIŠTE" />
            <div className="card-body">
              <h5 className="card-title">BOSSIN d.o.o. – REGALNO SKLADIŠTE</h5>
              <p className="card-text">Automatizacija procesa sušare i upravljanja regalnim skladištem</p>
            </div>
            <div className="card-footer">
              <small className="text-muted">.</small>
            </div>
          </div>
        </div>
        <div className="col">
          <div className="card h-100">
            <img src={Image5} className="card-img-top" alt="BROD BETONARA"/>
            <div className="card-body">
              <h5 className="card-title">STAV d.o.o./Brodosplit d.d.-BROD BETONARA</h5>
              <p className="card-text">Upravljanje betonarom i software za praćenje proizvodnje betona</p>
            </div>
            <div className="card-footer">
              <small className="text-muted">.</small>
            </div>
          </div>
        </div>
        <div className="col">
          <div className="card h-100">
            <img src={Image6} className="card-img-top" alt="ZAGREBAČKO KAZALIŠTE LUTAKA" />
            <div className="card-body">
              <h5 className="card-title">ZAGREBAČKO KAZALIŠTE LUTAKA – AUTOMATSKO UPRAVLJANJE SCENOM</h5>
              <p className="card-text">Jedini projekt izvan naše djelatnosti, no od značajnog doprinosa široj društvenoj zajednici</p>
            </div>
            <div className="card-footer">
              <small className="text-muted">.</small>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LastProjects;