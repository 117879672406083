import React from 'react';
import {useFontSizeStore} from "../stores/useFontSizeStore";

const SelectTextSize = () => {

  const options = useFontSizeStore(state => state.defs)
  const textSizeSelected = useFontSizeStore(state => state.textSizeSelected)
  const setTextSize = useFontSizeStore(state => state.setTextSize)

  return (
    <div className="container">
      <div className="row">
        <div className="col-8">

        </div>
        <div className="col-4">
          <select
            className="form-select"
            aria-label="Select text size"
            value={textSizeSelected}
            onChange={e => setTextSize(e.target.value)}>
            {options.map(o => (
              <option key={o.value} value={o.value}>
                {o.name}
              </option>
            ))}
          </select>
        </div>
      </div>
    </div>
  );
};

export default SelectTextSize;
