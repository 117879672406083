import React from 'react';
import {useFontSizeStore} from "../stores/useFontSizeStore";
import Image1 from "../assets/tekuci_pigmenti.png";
const PigmentsFluid = () => {

  const options = useFontSizeStore(state => state.defs)
  const textSizeSelected = useFontSizeStore(state => state.textSizeSelected)

  return (
    <div className="container">
      <div className="row">
        <div className="col-8">
          <p style={options[textSizeSelected].styleHead}>TEKUĆI PIGMENTI ZA BETON</p>
        </div>
        <div className="col-4">

        </div>
      </div>
      <div className="row">
        <div className="col-lg-10 col-sm-12">
          <img src={Image1} className="ml-auto mr-auto" style={{display: 'block', width: '100%'}} alt="Pigmenti1" />
        </div>
      </div>

      <div className="row mt-3">
        <div className="col-12">
          <p style={options[textSizeSelected].styleNormalBold}>Tekući pigmenti – paste</p>
        </div>
      </div>

      <div className="row mt-1">
        <div className="col-lg-8 col-sm-12">
          <p style={options[textSizeSelected].styleNormal}>
            Izraz „tekuća boja“ odnosno pasta, inicijalno se odnosi na suspenziju pigmenta u vodi. Jednostavna suspenzija (mješavina vode i pigmenta), tek nakon obrade (dispergiranjem i turbo-mlinom), te dodavanjem specijalnih aditiva kao što su dispergatori, dodaci za vlaženje, reološki aditivi, antipjenića, te sredstva za konzerviranje, postaje stabilna disperzija.
            <br />
            Većina sinetičkih pigmenata kao što su oksidi željeza, kroma i kobalta mogu biti korišteni za izradu tekućeg pigmenta. Za izradu tekućeg pigmenta, najčešće se koristi praškasti pigment  od kojeg se dispregiranjem uz određenu opremu i dodatke, aglomerati čestica svode na svoju primarnu veličinu.
            <br/>
            Naša receptura u kombinaciji s aditivima omogućava izradu tekućeg pigmenta s visokim sadržajem pigmenta (50-65%), uz zadržavanje stabilne koloristike, fizičkih svojstava, te stabilnosti pri skladištenju. Time je omogućena izrada tekućeg pigmenta u svim mogućim tonovima boje.
            <br/>
            Prednosti tekućeg pigmenta u odnosu na praškasti iskazuju se s mogućnošću preciznog automatskog doziranja, te reduciranjem prašnjavosti.
          </p>
        </div>
        <div className="col-lg-4 col-sm-12">

        </div>
      </div>

      <div className="row mt-3">
        <div className="col-12">
          <p style={options[textSizeSelected].styleNormalBold}>Karakteristike proizvedenog tekućeg pigmenta</p>
        </div>
      </div>

      <div className="row mt-1">
        <div className="col-lg-8 col-sm-12">
          <p style={options[textSizeSelected].styleNormal}>
            Definirane specifikacije za svaku proizvedenu šaržu osiguravaju konstantu u kvaliteti od isporuke do isporuke, kao i u samoj aplikaciji. Te specifikacije sadrže kolorimetrijska svojstva, relativnu moć obojenja, kao i fizička svojstva u koja spadaju viskozitet, pH vrijednost, sadržaj suhe tvari i gustoća.
            <br/>
            Sukladnost specifikacijama svake proizvedene šarže tekućeg pigmenta osigurana je internom kontrolom kvalitete prema standardu ISO 9001:2015.
          </p>
        </div>
        <div className="col-lg-4 col-sm-12">

        </div>
      </div>


      <div className="row mt-4">
        <div className="col-12">
          <p style={options[textSizeSelected].styleNormalBold}>Najčešća upotreba</p>
        </div>
      </div>

      <div className="row mt-1">
        <div className="col-lg-8 col-sm-12">
          <p style={options[textSizeSelected].styleNormal}>
            Tekući pigment najčešće služi obojenju betona u industriji proizvodnje betonskih opločnika, betonskih blokova, betonskog crijepa, rubnjaka te ostalih nearmiranih betonskih elemenata. Za tu namjenu koriste se isključivo sintetički oksidi željeza, kroma i kobalta, jer na taj način osiguravamo izuzetnu otpornost na vremenske utjecaje, UV stabilnost, te otpornost na kiseline i lužine.
            <br/>
          </p>
        </div>
        <div className="col-lg-4 col-sm-12">

        </div>
      </div>

      <div className="row mt-4">
        <div className="col-12">
          <p style={options[textSizeSelected].styleNormalBold}>Pakiranje</p>
        </div>
      </div>

      <div className="row mt-1">
        <div className="col-lg-8 col-sm-12">
          <p style={options[textSizeSelected].styleNormal}>
            Dostupna su pakiranja u plastičnim bačvama sadržaja od 200kg do IBC kontejnera od 1000kg.
            <br/>
          </p>
        </div>
        <div className="col-lg-4 col-sm-12">

        </div>
      </div>


    </div>
  );
};

export default PigmentsFluid;
