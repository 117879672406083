import create from 'zustand'

const textDefinitions = [
  {
    name: 'Veličina slova 1',
    value: 0,
    styleOver: {color: '#000000', fontSize: '40px'},
    styleHead: {color: '#000000', fontSize: '30px'},
    styleNormal: {color: '#000000', fontSize: '18px'},
    styleNormalBold: {color: '#000000', fontSize: '19px', fontWeight: 'bold'},
    styleNormalLight: {color: '#777777', fontSize: '18px'},
    styleSub: {color: '#828291', fontSize: '12px'}
  },
  {
    name: 'Veličina slova 2',
    value: 1,
    styleOver: {color: '#000000', fontSize: '50px'},
    styleHead: {color: '#000000', fontSize: '35px'},
    styleNormal: {color: '#000000', fontSize: '25px'},
    styleNormalBold: {color: '#000000', fontSize: '26px', fontWeight: 'bold'},
    styleNormalLight: {color: '#777777', fontSize: '25px'},
    styleSub: {color: '#828291', fontSize: '18px'}
  },
  {
    name: 'Veličina slova 3',
    fontSize: '35px',
    value: 2,
    styleOver: {color: '#000000', fontSize: '60px'},
    styleHead: {color: '#000000', fontSize: '40px'},
    styleNormal: {color: '#000000', fontSize: '35px'},
    styleNormalBold: {color: '#000000', fontSize: '36px', fontWeight: 'bold'},
    styleNormalLight: {color: '#777777', fontSize: '35px'},
    styleSub: {color: '#828291', fontSize: '24px'}
  },
  {
    name: 'Veličina slova 4',
    value: 3,
    styleOver: {color: '#000000', fontSize: '65px'},
    styleHead: {color: '#000000', fontSize: '45px'},
    styleNormal: {color: '#000000', fontSize: '40px'},
    styleNormalBold: {color: '#000000', fontSize: '41px', fontWeight: 'bold'},
    styleNormalLight: {color: '#777777', fontSize: '40px'},
    styleSub: {color: '#828291', fontSize: '30px'}
  },
]

const useFontSizeStore = create((set) => ({
  textSizeSelected: 0,
  defs: textDefinitions,
  increasePopulation: () => set(state => ({ textSizeSelected: state.textSizeSelected + 1 })),
  setTextSize: (textSizeSelected) =>
    set((state) => ({
      ...state,
      textSizeSelected,
    })),
}));

export {useFontSizeStore}