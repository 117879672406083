import React from 'react';
import {useFontSizeStore} from "../stores/useFontSizeStore";
import Image1 from "../assets/plavi_ultramarin.png";
const PigmentsBlue = () => {

  const options = useFontSizeStore(state => state.defs)
  const textSizeSelected = useFontSizeStore(state => state.textSizeSelected)

  return (
    <div className="container">
      <div className="row">
        <div className="col-8">
          <p style={options[textSizeSelected].styleHead}>PLAVI ULTRAMARINE</p>
        </div>
        <div className="col-4">

        </div>
      </div>

      <div className="row">
        <div className="col-lg-10 col-sm-12">
          <img src={Image1} className="ml-auto mr-auto" style={{display: 'block', width: '100%'}} alt="PigmentBlue" />
        </div>
      </div>

      <div className="row mt-1">
        <div className="col-lg-8 col-sm-12">
          <p style={options[textSizeSelected].styleNormal}>
            Ultramarine plavi je sintetički pigment plave boje. Kemijski,to je natrijev alumino-sulfosilikat. Taj pigment ima jedinstvenu čistu i živu nijansu plave boje.  Zbog svoje boje veoma je primjenjiv kao izbjeljivač odnosno neutralizator žutih nijansi, te se kao takav široko primjenjuje u industriji boja, plastike, gume, papira, te u kemijskoj industriji.
            <br/>
            To je bezopasan pigment čija je sigurnost neupitna. Odobren je po svim standardima, te se kao takav može koristiti u izradi ambalaže za hranu, igračaka, te u proizvodnji kozmetike.
            Glavne značajke:
            <br/>
            - Temperaturna stabilnost do 350°C
            <br/>
            - Veoma lako dispergiranje
            <br/>
            - Odlična postojanost na svjetlo, te otpornost na atmosferilije
            <br/>
            - Odličan odnos cijene i performansi
            <br/>
            - Ekološki prihvatljiv
          </p>
        </div>
        <div className="col-lg-4 col-sm-12">

        </div>
      </div>



    </div>
  );
};

export default PigmentsBlue;
