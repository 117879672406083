import React from 'react';
import logo from '../assets/logo4.png'
import {Link, NavLink} from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars } from '@fortawesome/free-solid-svg-icons'
import SelectTextSize from "../common/SelectTextSize";

const Navbar = (props) => {
  const {location} = props

  return (
    <>
      <nav className="navbar navbar-expand-lg navbar-custom">
        <div className="container">
          <Link to="/" className="navbar-brand"><img src={logo} alt='Pinkys logo'/></Link>
        <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
          <FontAwesomeIcon icon={faBars} style={{color: 'white'}}/>
        </button>

        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav ml-auto">
            <li className="nav-item">
              <NavLink
                exact
                activeClassName="navbar__link--active"
                className="navbar__link"
                to="/"
              >
                Home
              </NavLink>

            </li>
            <li className="nav-item">
              <NavLink
                activeClassName="navbar__link--active"
                className="navbar__link"
                to="/pigments"
              >
                Pigmenti
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                activeClassName="navbar__link--active"
                className="navbar__link"
                to="/aditives"
              >
                Aditivi
              </NavLink>

            </li>
            <li className="nav-item">
              <NavLink
                activeClassName="navbar__link--active"
                className="navbar__link"
                to="/equipment"
              >
                Oprema
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                activeClassName="navbar__link--active"
                className="navbar__link"
                to="/automation"
              >
                Automatika
              </NavLink>
            </li>
          </ul>
          <SelectTextSize />
        </div>
        </div>
      </nav>
    </>
  );
};

export default Navbar;

