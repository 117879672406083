import React from 'react';
import {useFontSizeStore} from "../stores/useFontSizeStore";
import Image1 from "../assets/kalibracija_300.jpeg";

const Automation8 = () => {

  const options = useFontSizeStore(state => state.defs)
  const textSizeSelected = useFontSizeStore(state => state.textSizeSelected)

  return (
    <>

      <img src={Image1} className="ml-auto mr-auto" style={{display: 'block', width: '80%'}} alt="Auto" />
      <div className="container">
        <div className="row">
          <div className="col-12">
            <p style={options[textSizeSelected].styleHead}>KALIBRACIJA VLAGOMJERA</p>
          </div>
        </div>

        <div className="row mt-1">
          <div className="col-12">
            <p style={options[textSizeSelected].styleNormal}>
              Vlagomjer ima važnu ulogu u betonskoj industriji. Određivanje vlage u pojedinim agregatima se propisuje zakonski. Norma obvezuje proizvođača betona koji nema vlagomjer da se vlaga agregata određuje svaki dan dok se vlagomjerom to čini svakih 7 dana. Vlagomjer se može kalibrirati pomoću našeg korisnički jednostavnog software-a.</p>
          </div>
        </div>

      </div>
    </>
  );
};

export default Automation8;