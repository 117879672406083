import React from 'react';
import {useFontSizeStore} from "../stores/useFontSizeStore";

const Equipment2 = () => {

  const options = useFontSizeStore(state => state.defs)
  const textSizeSelected = useFontSizeStore(state => state.textSizeSelected)

  return (
    <>
      <div className="container">

        <div className="row mt-1">
          <div className="col-lg-10 col-sm-12">
            <p style={options[textSizeSelected].styleNormal}>
              Kako se pri radu se pojavljuju brojni problemi: određivanje potrebne količine i vrste dodataka i pigmenata (praškasti, granule ili tekući), priprema tekućeg pigmenta, miješanje dodataka u beton, kada ih dodati (prije doziranja vode, poslije doziranja vode...), trošenje dijelova strojeva, PINKY-S je počeo proizvoditi specifičnu opremu za rad:
            </p>
          </div>
          <div className="col-lg-2 col-sm-12">

          </div>
        </div>

        <div className="row mt-1">
          <div className="col-lg-10 col-sm-12">
            <p style={options[textSizeSelected].styleNormal}>
              • strojeve za pripremu i doziranje praškastih i granulastih pigmenata <br/>
              • strojeve za pripremu i doziranje tekućih pigmenata<br/>
              • aparate za nanošenje premaza<br/>
              • punilice boja<br/>
              • opremu za doziranje kemijskih dodataka (aditiva)<br/>
              • rezervne dijelove opreme za proizvodnju betonskog crijepa i drugih betonskih proizvoda<br/>
            </p>
          </div>
          <div className="col-lg-2 col-sm-12">

          </div>
        </div>


        <div className="row mt-1">
          <div className="col-lg-10 col-sm-12">
            <p style={options[textSizeSelected].styleNormal}>
              Pri rješavanju Vaših tehnoloških problema PINKY-S koristi vlastite inovacije, čime pomaže poboljšanju kvalitete Vaših gotovih proizvoda. U suradnji sa svojim poslovnim partnerima PINKY-S obavlja i servis betonara.
            </p>
          </div>
          <div className="col-lg-2 col-sm-12">

          </div>
        </div>

      </div>
    </>
  );
};

export default Equipment2;
