import React from 'react';
import {useFontSizeStore} from "../stores/useFontSizeStore";
import Image3 from "../assets/rsz_wetophobe.png";
import Image2 from '../assets/plastifikator.jpeg'
import Dots from "../elements/Dots";
import Image1 from "../assets/Aditivi_uvodna.png";

const Aditives3 = () => {

  const options = useFontSizeStore(state => state.defs)
  const textSizeSelected = useFontSizeStore(state => state.textSizeSelected)

  return (
    <>
      <div className="container">

        <div className="row">
          <div className="col-12">
            <p style={options[textSizeSelected].styleHead}>DODACI ZA ZEMLJO-VLAŽNE BETONE</p>
          </div>
        </div>

        <img src={Image1} className="ml-auto mr-auto" style={{display: 'block', width: '100%'}} alt="Auto" />

        <div className="row mt-1">
          <div className="col-12">
            <p style={options[textSizeSelected].styleNormal}>
              Zbog mnogih specifičnosti zemljo-vlažnih betona koristimo kemijske dodatke kako bismo poboljšali kvalitetu gotovih proizvoda izrađenih od takvih betona.<br/><br/>
              Proizvodnja proizvoda od zemljo-vlažnih betona relativno je nova  tehnologija koja brzo napreduje.<br/><br/>
              Nove vrste automatiziranih strojeva za izradu proizvoda od zemljo-vlažnih betona iziskuju posebnu pažnju kod projektiranja receptura za takve betone. Danas je nezamislivo izrađivati proizvode bez specijalnih dodataka, jer se od tih proizvoda osim funkcionalnosti očekuje lijep izgled, otpornost na ekstremne vremenske utjecaje i velika opterećenja.<br/><br/>
              Kapaciteti strojeva također ovise o kemijskim dodacima , jer nekim svojim svojstvima omogućuju skraćenje proizvodnog procesa i smanjeno trošenje dijelova stroja, pa time bitno smanjuju troškove proizvodnje.<br/><br/>
              Takvi dodaci imaju utjecaj i na kvalitetu okoliša jer direktno utječu na smanjenje buke i utroška energije.<br/>
            </p>
          </div>
        </div>

        <div className="row mt-2">
          <div className="col-12">
            <p style={options[textSizeSelected].styleHead}>PLASTIFIKATORI</p>
          </div>
        </div>
        <img src={Image2} className="ml-auto mr-auto" style={{display: 'block', width: '80%'}}  alt="Plastifikator3" />

        <div className="row mt-2">
          <div className="col-12">
            <div className="table-responsive-sm">
              <table className="table table-bordered">
                <thead>
                <tr>
                  <th scope="col" className="text-left"><p style={options[textSizeSelected].styleNormal}>PLASTIFIKATORI</p></th>
                  <th scope="col" className="text-center"><p style={options[textSizeSelected].styleNormal}>BETONSKE CIJEVI</p></th>
                  <th scope="col" className="text-center"><p style={options[textSizeSelected].styleNormal}>OPLOČNICI<br/>NOSIVI SLOJ</p></th>
                  <th scope="col" className="text-center"><p style={options[textSizeSelected].styleNormal}>OPLOČNICI I RUBNJACI <br/>ZAVRŠNI SLOJ</p></th>
                  <th scope="col" className="text-center"><p style={options[textSizeSelected].styleNormal}>BLOKOVI</p></th>
                </tr>
                </thead>
                <tbody>
                <tr>
                  <th scope="row" className="text-left"><p style={options[textSizeSelected].styleNormal}>WET MIX 01</p></th>
                  <td className="text-center"><Dots numberOf="4"/></td>
                  <td className="text-center"><Dots numberOf="4"/></td>
                  <td className="text-center"><Dots numberOf="1"/></td>
                  <td className="text-center"><Dots numberOf="5"/></td>
                </tr>
                <tr>
                  <th scope="row" className="text-left"><p style={options[textSizeSelected].styleNormal}>RHEOGLIDE F45A</p></th>
                  <td className="text-center"><Dots numberOf="5"/></td>
                  <td className="text-center"><Dots numberOf="5"/></td>
                  <td className="text-center"><Dots numberOf="4"/></td>
                  <td className="text-center"><Dots numberOf="5"/></td>
                </tr>
                <tr>
                  <th scope="row" className="text-left"><p style={options[textSizeSelected].styleNormal}>WETGLIDE 60</p></th>
                  <td className="text-center"><Dots numberOf="4"/></td>
                  <td className="text-center"><Dots numberOf="4"/></td>
                  <td className="text-center"><Dots numberOf="4"/></td>
                  <td className="text-center"><Dots numberOf="5"/></td>
                </tr>
                <tr>
                  <th scope="row" className="text-left"><p style={options[textSizeSelected].styleNormal}>WETGLIDE 01 A</p></th>
                  <td className="text-center"><Dots numberOf="4"/></td>
                  <td className="text-center"><Dots numberOf="3"/></td>
                  <td className="text-center"><Dots numberOf="3"/></td>
                  <td className="text-center"><Dots numberOf="1"/></td>
                </tr>
                <tr>
                  <th scope="row" className="text-left"><p style={options[textSizeSelected].styleNormal}>WETOPHOBE H80</p></th>
                  <td className="text-center"><Dots numberOf="4"/></td>
                  <td className="text-center"><Dots numberOf="4"/></td>
                  <td className="text-center"><Dots numberOf="4"/></td>
                  <td className="text-center"></td>
                </tr>
                <tr>
                  <th scope="row" className="text-left"><p style={options[textSizeSelected].styleNormal}>EFFLO-WET 3100</p></th>
                  <td className="text-center"></td>
                  <td className="text-center"><Dots numberOf="3"/></td>
                  <td className="text-center"><Dots numberOf="4"/></td>
                  <td className="text-center"></td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>


        <div className="row mt-2">
          <div className="col-12">
            <p style={options[textSizeSelected].styleHead}>DODACI ZA HIDROFOBIRANJE I SMANJENJE EFLORESCENCIJE</p>
          </div>
        </div>

        <img src={Image3} className="ml-auto mr-auto" style={{display: 'block', width: '80%'}}  alt="Plastifikator3" />

        <div className="row mt-2">
          <div className="col-12">
            <div className="table-responsive-sm">
              <table className="table table-bordered">
                <thead>
                <tr>
                  <th scope="col" className="text-left"><p style={options[textSizeSelected].styleNormal}>HIDROFOBI</p></th>
                  <th scope="col" className="text-center"><p style={options[textSizeSelected].styleNormal}>BETONSKE CIJEVI</p></th>
                  <th scope="col" className="text-center"><p style={options[textSizeSelected].styleNormal}>OPLOČNICI<br/>NOSIVI SLOJ</p></th>
                  <th scope="col" className="text-center"><p style={options[textSizeSelected].styleNormal}>OPLOČNICI I RUBNJACI <br/>ZAVRŠNI SLOJ</p></th>
                  <th scope="col" className="text-center"><p style={options[textSizeSelected].styleNormal}>BLOKOVI <br/>UKRASNI</p></th>
                </tr>
                </thead>
                <tbody>
                <tr>
                  <th scope="row" className="text-left"><p style={options[textSizeSelected].styleNormal}>HYDROPHOBE 01</p></th>
                  <td className="text-center"><Dots numberOf="4"/></td>
                  <td className="text-center"><Dots numberOf="4"/></td>
                  <td className="text-center"><Dots numberOf="4"/></td>
                  <td className="text-center"><Dots numberOf="5"/></td>
                </tr>
                <tr>
                  <th scope="row" className="text-left"><p style={options[textSizeSelected].styleNormal}>WETOPHOBE W2</p></th>
                  <td className="text-center"><Dots numberOf="5"/></td>
                  <td className="text-center"><Dots numberOf="5"/></td>
                  <td className="text-center"><Dots numberOf="5"/></td>
                  <td className="text-center"><Dots numberOf="5"/></td>
                </tr>
                <tr>
                  <th scope="row" className="text-left"><p style={options[textSizeSelected].styleNormal}>EFFLO-WET 01</p></th>
                  <td className="text-center"></td>
                  <td className="text-center"><Dots numberOf="2"/></td>
                  <td className="text-center"><Dots numberOf="4"/></td>
                  <td className="text-center"><Dots numberOf="4"/></td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>

      </div>
    </>
  );
};

export default Aditives3;
