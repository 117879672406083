import React from 'react';
import {useFontSizeStore} from "../stores/useFontSizeStore";
import Image1 from "../assets/punilica_program.jpeg";

const Automation10 = () => {

  const options = useFontSizeStore(state => state.defs)
  const textSizeSelected = useFontSizeStore(state => state.textSizeSelected)

  return (
    <>

      <img src={Image1} className="ml-auto mr-auto" style={{display: 'block', width: '80%'}} alt="Auto" />
      <div className="container">
        <div className="row">
          <div className="col-12">
            <p style={options[textSizeSelected].styleHead}>PUNILICA BOJE</p>
          </div>
        </div>

        <div className="row mt-1">
          <div className="col-12">
            <p style={options[textSizeSelected].styleNormal}>Punilica boje je samostalni poluautomatski ili automatski uređaj. Punilica ima ekran osjetljiv na dodir. Na ekranu se unose osnovni parametri: količina, preticanje, postotak grubog doziranja dok se za ostale postavke može koristiti tipkovnica.
            <br/>
              Software i oprema su u cijelosti projektirani i izrađeni u PINKY-S d.o.o.
            </p>
          </div>
        </div>

      </div>
    </>
  );
};

export default Automation10;