import React from 'react';
import {useFontSizeStore} from "../stores/useFontSizeStore";
import Cert1 from '../assets/ISO_9001.jpg'
import Cert2 from '../assets/ISO_14001.jpg'
import Cert3 from '../assets/certifikat_aditivi.jpeg'
import Cert4 from '../assets/certifikat_svi.jpeg'


const Certificates = () => {

  const options = useFontSizeStore(state => state.defs)
  const textSizeSelected = useFontSizeStore(state => state.textSizeSelected)

  return (
    <div className="container">

      <div className="row">
        <div className="col-8">
          <p style={options[textSizeSelected].styleOver}>Certifikati</p>
        </div>
        <div className="col-4">

        </div>
      </div>

      <div className="row mt-3">
        <div className="col-12">
          <p style={options[textSizeSelected].styleHead}>ISO 9001</p>
        </div>
      </div>

      <div className="row mt-3">
        <div className="col-lg-8 col-sm-12">
          <p style={options[textSizeSelected].styleNormal}>
            Poduzeće PINKY-S d.o.o. uvelo je sustav upravljanja kvalitetom u svrhu postizanja najvišeg mogućeg nivoa kvalitete svojih usluga i proizvoda
            <br/>
            <br/>
            -Distribucija pigmenata
            <br/>
            -Proizvodnja boja i premaza na bazi vode
            <br/>
            -Konzalting za pigmente
            <br/>
            -Proizvodnja suspenzija i disperzija pigmenata
            <br/>
            -Projektiranje, proizvodnja i servisiranje strojeva specijalne namjene u betonskoj industriji.
            <br/>
            <br/>
            Cilj politike kvalitete je potpuno razumijevanje konteksta organizacije, zahtjeva koje smo kao organizacija preuzeli, zahtjeva relevantnih zainteresiranih strana i naročito zahtjeva kupca kojemu pružamo uslugu ili isporučujemo proizvod.
            <br/>
            <br/>
            Cilj svih zaposlenih je biti podrška u ostvarenju ciljeva organizacije , te u potpunosti razumjeti zahtjeve naših kupaca i partnera.
            <br/>
            <br/>
            -rukovodimo potrebama kupaca
            <br/>
            -uključujemo sve zainteresirane strane
            <br/>
            -održavamo tehničku suradnju i partnerske odnose s kupcima i dobavljačima
            <br/>
            -razvijamo pošten, kulturan i korektan pristup s poslovnim partnerima
            <br/>
            -razvijamo odgovornost, točnost i pravovremenost
            <br/>
            -težimo stalnom unapređenju, poboljšanju i razvoju
            <br/>
            -nastojimo ostvariti kvalitetnu radnu okolinu i zadovoljstvo zaposlenika
            <br/>
            -potičemo načelo vođenja
            <br/>
            <br/>
            Sustav upravljanja kvalitetom temeljen na zahtjevima norme ISO 9001:2015. održavat ćemo uz pomoć ovlaštene, međunarodno priznate i neutralne certifikacijske ustanove.
          </p>
        </div>
        <div className="col-lg-4 col-sm-12">
          <img src={Cert1} className="img-fluid"  alt="ISO 9001 certificate" />
        </div>
      </div>


      <div className="row mt-3">
        <div className="col-12">
          <p style={options[textSizeSelected].styleHead}>ISO 14001</p>
        </div>
      </div>

      <div className="row mt-3">
        <div className="col-lg-8 col-sm-12">
          <p style={options[textSizeSelected].styleNormal}>
            PINKY-S d.o.o. se obvezuje štiti okoliš na lokacijama na kojim provodi svoje aktivnosti proizvodnje, pakiranja i skladištenja ili daje usluge primjereno prirodi, opsegu i utjecaju na okoliš vlastitih djelatnosti ili usluga.
            <br/>
            Kao organizacija svjesna okoliša obvezujemo se:
            <br/>
            -primjenjivati procese i tehnologiju nadzora za smanjenje zagađivanja do mogućeg opsega
            <br/>
            -udovoljavati važećim zakonskim zahtjevima i zahtjevima ugovora, te postavljati interne standarde i zahtjeve kada je to potrebno
            <br/>
            -stalno poboljšavati i sprječavati onečišćenja
            <br/>
            -kada je primjereno provoditi korektivne akcije koje su se pokazale učinkovite
            <br/>
            -nastojati stalno poboljšavati sustav upravljanja okolišem
            <br/>
            -pratiti zahtjeve zakona i propisa vezane za procese
            <br/>
            Sustav upravljanja okolišem temelji se na zahtjev norme ISO 14001:2015. Izgrađeni sustav upravljanja okolišem održavat ćemo uz pomoć ovlaštene, međunarodno priznate i neutralne certifikacijske ustanove.
            <br/>
            <br/>
            Poslovodstvo poduzeća u cijelosti podržava politiku i ciljeve okoliša.
            <br/>
            Dužnost je svakog djelatnika poduzeća da primjenjuje postupke koji se odnose na njegovo područje.
          </p>
        </div>
        <div className="col-lg-4 col-sm-12">
          <img src={Cert2} className="img-fluid"  alt="ISO 14001 certificate" />
        </div>
      </div>


      <div className="row mt-3">
        <div className="col-12">
          <p style={options[textSizeSelected].styleHead}>Certifikacijom proizvoda do tržišta</p>
        </div>
      </div>

      <div className="row mt-3">
        <div className="col-lg-8 col-sm-12">
          <p style={options[textSizeSelected].styleNormal}>
            Tvrtka Pinky-s svima je poznata po svojoj dugogodišnjoj tradiciji u betonskoj industriji. Na tržištu se nalazimo već 25 godina i u cilju maksimalnog zadovoljenja potreba naših kupaca odlučili smo upotpuniti naš proizvodni asortiman dodacima za beton. Nakon više godišnjeg istraživanja naš stručni tim inženjera kemije razvio je proizvode upravo za vaše potrebe. U našem asortimanu nalazi se osam novo certificiranih dodataka za transportni beton i predgotovljene betonske elemente. Svaki proizvod smo mjesecima testirali u našem laboratoriju u cilju da se dovedu do savršenstva i da se zadovolje potreba kupaca u betonskoj industriji.
          </p>
        </div>
        <div className="col-lg-4 col-sm-12">
          <img src={Cert3} className="img-fluid"  alt="Certificate" />
        </div>
      </div>



      <div className="row mt-3">
        <div className="col-12">
          <p style={options[textSizeSelected].styleHead}>Proizvodi</p>
        </div>
      </div>

      <div className="row mt-3">
        <div className="col-lg-8 col-sm-12">
          <p style={options[textSizeSelected].styleNormal}>
            RHEOGLIDE L 10
            <br/>
            Super-plastifikator za transportne betone s produljenim vremenom ugradnje
            <br/>
            <br/>
            RHEOGLIDE L 30
            <br/>
            Super-plastifikator za transportne betone s produljenim vremenom ugradnje primjeren drobljenim agregatima s manjom količinom finih čestica
            <br/>
            <br/>
            RHEOGLIDE L60
            <br/>
            Ekonomičan i robustan super-plastifikator za transportne betone s produljenim vremenom ugradnje.
            <br/>
            <br/>
            RHEOGLIDE F45
            <br/>
            Super-plastifikator za predgotovljenje betone s ranim prirastom čvrstoća i produljenim vremenom ugradnje
            <br/>
            <br/>
            RHEOGLIDE F75
            <br/>
            Super-plastifikator za predgotovljene betonske elemente s ranim prirastom čvrstoća i produljenim vremenom ugradnje
            <br/>
            <br/>
            PLASTOGLIDE W63
            <br/>
            Plastifikator za transportne betone i predgotovljenje betone u svrhu bolje obradivosti
            <br/>
            <br/>
            AIRGLIDE A11
            <br/>
            Aerant
            <br/>
            <br/>
            WETOPHOBE W2
            <br/>
            Aerant
          </p>
        </div>
        <div className="col-lg-4 col-sm-12">
          <img src={Cert4} className="img-fluid"  alt="Certificate products" />
        </div>
      </div>

    </div>
  );
};

export default Certificates;
