import React from 'react';
import {useFontSizeStore} from "../stores/useFontSizeStore";
import Image1 from "../assets/betonska_galanterija.jpeg";

const Automation5 = () => {

  const options = useFontSizeStore(state => state.defs)
  const textSizeSelected = useFontSizeStore(state => state.textSizeSelected)

  return (
    <>

      <img src={Image1} className="ml-auto mr-auto" style={{display: 'block', width: '100%'}} alt="Auto" />
      <div className="container">
        <div className="row">
          <div className="col-12">
            <p style={options[textSizeSelected].styleHead}>BETONSKE KONSTRUKCIJE I GALANTERIJA</p>
          </div>
        </div>

        <div className="row mt-1">
          <div className="col-12">
            <p style={options[textSizeSelected].styleNormal}>Izrada betonske galanterije (opločnik, betonski panel i ploča, rubnik i sl.), u raznim bojama je složen proces, a organizacija i postavke software-a direktno utječu na kvalitetu proizvoda. 20 godišnje iskustvo uloženo u razvoj proizvodnje betonske galanterije garantira Vam da su Pinky-s software i Pinky-s oprema najbolje rješenje za Vas.</p>
          </div>
        </div>

      </div>
    </>
  );
};

export default Automation5;